/** @jsxImportSource theme-ui */
import MimeEvents from './MimeEvents';
import PromoSection from './PromoSection';
import type { PromoCard } from '../../types';
import { screenReadersOnlySx } from '../../utils/screen-readers';

export type Props = {
  content: string;
  promos: { cards: PromoCard[] };
  title: string;
};

const IndexPageTemplate = ({ content, promos, title }: Props) => (
  <div>
    <h1 sx={screenReadersOnlySx}>{title}</h1>
    <PromoSection promos={promos} />
    <MimeEvents content={content} />
  </div>
);

export default IndexPageTemplate;
