import type { ThemeUICSSProperties, ThemeUIStyleObject } from 'theme-ui';

export const containerSpacingSx: Pick<
  ThemeUICSSProperties,
  'paddingBottom' | 'paddingTop' | 'px'
> = {
  px: [4, null, 5],
  paddingBottom: [6, null, 7],
  paddingTop: [6, null, 7],
};

export const containerSpacingSmallerSx: Pick<
  ThemeUICSSProperties,
  'px' | 'py'
> = {
  px: [4, null, 5],
  py: [5, null, 6],
};

export const itemSpacingSx: ThemeUIStyleObject = {
  marginBottom: 8,
  '&:last-child': { marginBottom: 0 },
};
