import Club from './Club';
import ClubImageRoll from './ClubImageRoll';
import InfoRoll from '../../components/InfoRoll';
import LogoSection from '../../components/LogoSection';
import Section from '../../components/Section';
import type {
  ClubImages,
  ImageData,
  ImageOffsetsX,
  ImageSettings,
  InfoRollInfo,
  LangKey,
  Performances,
  PersonInfos,
} from '../../types';
import PersonRoll from '../../components/PersonRoll';
import PerformanceRoll from '../../components/PerformanceRoll';
import TextContent from '../../components/TextContent';

type Props = {
  additionalInfo: string;
  clubImages1: ClubImages;
  clubImages2: ClubImages;
  content: string;
  credits: { credit: string }[];
  image: ImageData;
  imageOffsetsX: ImageOffsetsX;
  imageOffsetY?: string;
  imageSettings?: ImageSettings;
  info: InfoRollInfo;
  langKey: LangKey;
  logos: string[];
  performances: Performances;
  performers: PersonInfos;
  title: string;
};

const ClubPageTemplate = ({
  additionalInfo,
  clubImages1,
  clubImages2,
  content,
  image,
  imageOffsetsX,
  imageOffsetY,
  imageSettings,
  info,
  langKey,
  logos,
  performances,
  performers,
  title,
}: Props) => (
  <div>
    <Club
      content={content}
      image={image}
      imageOffsetsX={imageOffsetsX}
      imageOffsetY={imageOffsetY}
      imageSettings={imageSettings}
      langKey={langKey}
      title={title}
    />
    {!info.hide && (
      <Section variant="blue" containerStyle={{ px: 0 }}>
        <InfoRoll credits={info.credits} items={info.items} langKey={langKey} />
      </Section>
    )}
    <ClubImageRoll {...clubImages2} />
    {additionalInfo && (
      <Section variant="blueDark">
        <TextContent
          content={additionalInfo}
          style={{ margin: 'auto', maxWidth: 'container' }}
        />
      </Section>
    )}
    {!performances.hide && (
      <Section variant="black">
        <PerformanceRoll
          heading={performances.title}
          performances={performances.descriptions}
        />
      </Section>
    )}
    {!performers.hide && (
      <Section variant="purpleWithWave">
        <PersonRoll imageShape="roundedRectangle" infos={performers} />
      </Section>
    )}
    <ClubImageRoll {...clubImages1} />
    <LogoSection langKey={langKey} logos={logos} />
  </div>
);

export default ClubPageTemplate;
