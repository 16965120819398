/** @jsxImportSource theme-ui */
import { fadeInAnimation } from '../utils/animations';

type Props = {
  children: React.ReactNode;
};

const Main = ({ children }: Props) => (
  <main
    id="main-content"
    sx={{
      animation: fadeInAnimation,
      width: '100%',
      flex: '1 1 auto',
      outline: 0,
    }}
    tabIndex={-1}
  >
    {children}
  </main>
);

export default Main;
