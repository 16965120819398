import type { Theme, ThemeUIStyleObject } from 'theme-ui';

import { easings, timings } from './animations';

export const linkSx: ThemeUIStyleObject = {
  color: 'text',
  transition: `color ${timings[1]} ${easings[0]}`,
  '&:hover': {
    color: 'secondaryLightest',
  },
};
export const globalLinkStyle = (theme: Theme): ThemeUIStyleObject => ({
  color: theme.colors?.text,
  transition: `color ${timings[1]} ${easings[0]}`,
  '&:hover': {
    color: theme.colors?.secondaryLightest,
  },
});

export const lightBackgroundLinkColorSx: ThemeUIStyleObject = {
  a: { color: 'secondaryDark', '&:hover': { color: 'secondaryLight' } },
};

export const linkButtonSx: ThemeUIStyleObject = {
  backgroundColor: 'secondary',
  borderRadius: 1,
  color: 'text',
  display: 'inline-block',
  fontFamily: 'heading',
  fontSize: 1,
  fontWeight: 'bold',
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 4,
  paddingRight: 4,
  textDecoration: 'none',
  transition: `background-color ${timings[1]} ${easings[0]}`,
  '&:hover': {
    color: 'text',
    backgroundColor: 'secondaryLight',
  },
};

export const headerFooterLinkSx: ThemeUIStyleObject = {
  ...linkSx,
  color: 'black',
  textDecoration: 'none',
  borderBottomStyle: 'solid',
  borderColor: 'transparent',
  borderWidth: 1,
  transition: `border-color ${timings[1]} ${easings[0]}, color ${timings[1]} ${easings[0]}`,
  '&:hover, &[aria-current="page"]': {
    color: 'secondary',
    borderColor: 'secondary',
  },
};

export const getLinkId = (idPart: string) =>
  // Normalize non-ASCII characters, source https://stackoverflow.com/a/46192691/305436
  idPart
    .normalize('NFKD')
    .replace(/[^\w\s]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();
