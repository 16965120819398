/** @jsxImportSource theme-ui */
import { Link } from 'gatsby';
import type { ThemeUIStyleObject } from 'theme-ui';
import { headerFooterLinkSx } from '../utils/links';
import LinkExternal from '../components/LinkExternal';

type Props = {
  children: React.ReactNode;
  isInternal?: boolean;
  style?: ThemeUIStyleObject;
  to: string;
};

const FooterLink = ({ children, isInternal = false, style, to }: Props) =>
  isInternal ? (
    <Link
      activeClassName="active"
      to={to}
      sx={{
        ...headerFooterLinkSx,
        color: 'gray',
        marginRight: [4, null, 5],
        ...style,
      }}
    >
      {children}
    </Link>
  ) : (
    <LinkExternal
      href={to}
      style={{ ...headerFooterLinkSx, color: 'gray', ...style }}
    >
      {children}
    </LinkExternal>
  );

export default FooterLink;
