/** @jsxImportSource theme-ui */
import { defaultHeight } from './defaults';
import type { IconProps } from './types';

const YouTube = ({ height = defaultHeight, style, title }: IconProps) => (
  <svg
    stroke="currentColor"
    height={height}
    width={height}
    strokeWidth="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    sx={style}
  >
    {title && <title>{title}</title>}
    <g fill="none" strokeMiterlimit="10" transform="translate(0 1)">
      <rect height="17.18" rx="3.82" width="21" x="1.5" y="3.41" />
      <path d="m15.82 12-7.64 3.82v-7.64z" />
    </g>
  </svg>
);

export default YouTube;
