/** @jsxImportSource theme-ui */
import type { ThemeUIStyleObject } from 'theme-ui';

import type { Events } from '../types';
import List from './List';
import TextContent from './TextContent';

type Props = {
  events: Events;
  style?: ThemeUIStyleObject;
};

const EventRoll = ({ events, style }: Props) => (
  <div
    sx={{
      display: 'inline-block',
      width: 'auto',
      ...style,
    }}
  >
    <h2>{events.title}</h2>
    <List
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content auto',
        lineHeight: 'infoValue',
        textAlign: 'left',
      }}
    >
      {events.items.map((event) => (
        <li
          key={`${event.date} ${event.description}`}
          sx={{ display: 'contents' }}
        >
          <span sx={{ textAlign: 'right', paddingRight: 4 }}>{event.date}</span>
          <TextContent
            content={event.description}
            style={{ paddingBottom: 2 }}
          />
        </li>
      ))}
    </List>
  </div>
);

export default EventRoll;
