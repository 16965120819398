import { bodyFontFamily, headerFontFamily } from './font-stacks';

const theme = {
  useCustomProperties: true,
  fonts: {
    heading: headerFontFamily,
    body: bodyFontFamily,
  },
  fontSizes: [14, 16, 18, 20, 24, 30, 36, 40, 48, 64, 72, 96],
  fontWeights: {
    lite: 300,
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
    headingLink: 1.2,
    infoValue: 1.4,
    quote: 1.75,
    bannerText: 1.3,
    shortText: 1.1,
  },
  colors: {
    primary: '#28776B',
    secondary: 'hsl(337, 63%, 48%)',
    secondaryDark: 'hsl(337, 58%, 43%)',
    secondaryLight: 'hsl(337, 68%, 52%)',
    secondaryLightest: 'hsl(337, 68%, 85%)',
    text: '#fcfcfc',
    black: '#080808',
    blackPure: '#000',
    white: '#fcfcfc',
    whitePure: '#fff',
    gray: '#737373',
    grayLight: '#9B9B9B',
    grayLightest: '#ccc',
    background: '#010066',
    backgroundDark: '#01004D',
    laurel: '#16a400',
    mint: '#c8ffbf',
    green: '#90ff80',
    flirt: '#ad0099',
    pink: '#ffa380',
    lace: '#ffbff8',
    vermilion: '#ff4700',
    fire: '#b33200',
    romantic: '#ffd1bf',
    semiTransparent: 'rgba(0, 0, 0, 0.65)',
  },
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 128],
  breakpoints: ['24em', '32em', '48em', '56em', '70em'],
  radii: [0, 3, 6],
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)',
    sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
    text: '1px 1px 2px black',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    none: 'none',
  },
  sizes: {
    header: 1200,
    container: 800,
    containerWider: 900,
    containerWidest: 1000,
    main: 1200,
    footer: 1200,
    quote: 440,
    text: 600,
    textNarrow: 400,
    textPlusPadding: 664,
    sidebar: 400,
  },
  borderWidths: [0, 2, 4],
};

export default theme;
