/** @jsxImportSource theme-ui */
import { Flex } from '@theme-ui/components';
import { Link } from 'gatsby';
import { Fragment } from 'react';

import logo from '../img/logo.svg';
import logoM from '../img/logo-m.svg';
import type { LangKey } from '../types';
import { getOtherLangLabel, getLangPath } from '../utils/languages';
import NavbarLink from './NavbarLink';
import Spacer from '../components/Spacer';

type Props = {
  langKey: LangKey;
  pathOtherLang: string;
};

const navLinks: Record<
  LangKey,
  { label: string; link: string; classes?: string }
>[] = [
  {
    en: { label: 'Club', link: 'club/' },
    fi: { label: 'Klubi', link: 'klubi/' },
  },
  {
    en: { label: 'Workshops', link: 'workshops/' },
    fi: { label: 'Kurssit', link: 'kurssit/' },
  },
  {
    en: { label: 'Tours', link: 'tours/' },
    fi: { label: 'Kiertueet', link: 'kiertueet/' },
  },
  {
    en: { label: 'Videos', link: 'videos/' },
    fi: { label: 'Videot', link: 'videot/' },
  },
];

const Navbar = ({ langKey, pathOtherLang }: Props) => {
  const basePath = getLangPath(langKey);
  return (
    <nav role="navigation" aria-label="main-navigation">
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          lineHeight: 'headingLink',
        }}
      >
        <Link to={`${basePath}/`} sx={{ flexShrink: 0 }}>
          <img
            src={logo}
            alt="Helmime logo"
            sx={{
              display: ['none', null, null, 'block'],
              marginBottom: 0,
              width: '150px',
            }}
          />
          <img
            src={logoM}
            alt="Helmime logo"
            sx={{
              display: ['block', null, null, 'none'],
              marginBottom: 0,
              width: ['36px', '48px'],
            }}
          />
        </Link>
        <Flex
          sx={{
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: ['space-evenly', null, 'flex-end'],
          }}
        >
          <Spacer amount={[3, 5, 6]} />
          {navLinks.map((link, index) => (
            <Fragment key={link[langKey].link}>
              {index > 0 && <Spacer amount={[2, 4, 5]} />}
              <NavbarLink to={`${basePath}/${link[langKey].link}`}>
                {link[langKey].label}
              </NavbarLink>
            </Fragment>
          ))}
          <Spacer amount={[3, 5, 6]} />
        </Flex>
        <NavbarLink to={pathOtherLang}>{getOtherLangLabel(langKey)}</NavbarLink>
      </Flex>
    </nav>
  );
};

export default Navbar;
