import { graphql, PageProps } from 'gatsby';

import Layout from './layout/Layout';
import ClubPageTemplate from './pages/club/ClubPageTemplate';
import type {
  ClubImages,
  Frontmatter,
  ImageData,
  ImageOffsetsX,
  ImageSettings,
  InfoRollInfo,
  PageContext,
  Performances,
  PersonInfos,
} from './types';

export type Props = PageProps<
  {
    markdownRemark: {
      frontmatter: Frontmatter & {
        additionalInfo: string;
        clubImages1: ClubImages;
        clubImages2: ClubImages;
        credits: { credit: string }[];
        image: ImageData;
        imageOffsetsX: ImageOffsetsX;
        imageOffsetY?: string;
        imageSettings?: ImageSettings;
        info: InfoRollInfo;
        logos: string[];
        performances: Performances;
        performers: PersonInfos;
      };
      rawMarkdownBody: string;
    };
  },
  PageContext
>;

const ClubPage = ({ data, pageContext }: Props) => {
  const { frontmatter, rawMarkdownBody } = data.markdownRemark;

  return (
    <Layout
      langKey={pageContext.langKey}
      metadata={{
        description: frontmatter.meta.description,
        images: frontmatter.meta.images.map(({ image }) => image.publicURL),
        title: frontmatter.title,
      }}
      path={frontmatter.path}
      pathOtherLang={frontmatter.pathOtherLang}
    >
      <ClubPageTemplate
        {...frontmatter}
        content={rawMarkdownBody}
        langKey={pageContext.langKey}
      />
    </Layout>
  );
};

export default ClubPage;

export const pageQuery = graphql`
  query ClubPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      rawMarkdownBody
      fields {
        langKey
      }
      frontmatter {
        additionalInfo
        path
        pathOtherLang
        title
        meta {
          description
          images {
            image {
              publicURL
            }
          }
        }
        image {
          ...BackgroundImage
        }
        imageOffsetsX {
          _0_xs
          _1_sm
          _2_md
          _3_lg
          _4_xl
          _5_xxl
        }
        imageOffsetY
        imageSettings
        info {
          hide
          credits {
            credit
          }
          items {
            fieldGroup {
              label
              values {
                items {
                  icon
                  link
                  text
                  type
                }
              }
            }
          }
        }
        logos
        performances {
          hide
          title
          descriptions {
            title
            images {
              alt
              image {
                childImageSharp {
                  original {
                    width
                  }
                  narrow: gatsbyImageData(width: 320, height: 480)
                  wide: gatsbyImageData(width: 480, height: 320)
                  square: gatsbyImageData(width: 390, height: 390)
                }
              }
              shape
            }
            performer
            description
          }
        }
        performers {
          hide
          title
        }
        clubImages1 {
          credit
          description
          images {
            alt
            description
            image {
              childImageSharp {
                original {
                  width
                }
                wide: gatsbyImageData(width: 1152)
              }
            }
          }
          title
        }
        clubImages2 {
          credit
          description
          images {
            alt
            description
            descriptionPosition
            image {
              childImageSharp {
                original {
                  width
                }
                wide: gatsbyImageData(width: 1152)
              }
            }
          }
          title
        }
      }
    }
  }
`;
