/** @jsxImportSource theme-ui */
import type { ClubImages } from '../../types';
import ClubImage from './ClubImage';
import Section from '../../components/Section';
import TextContent from '../../components/TextContent';

type Props = ClubImages;

const ClubImageRoll = ({ credit, description, images, title }: Props) => (
  <Section
    as="article"
    variant="blackPure"
    containerStyle={{ textAlign: 'center' }}
  >
    {title && <h2>{title}</h2>}
    {description && (
      <TextContent content={description} style={{ margin: 'auto' }} />
    )}
    <div
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
      }}
    >
      {images.map((image) => (
        <ClubImage image={image} key={image.alt} />
      ))}
    </div>
    <TextContent
      content={credit}
      style={{ fontSize: 1, maxWidth: 'none', textAlign: 'center' }}
    />
  </Section>
);

export default ClubImageRoll;
