/** @jsxImportSource theme-ui */
import InfoList from '../../components/InfoList';
import TextContent from '../../components/TextContent';
import TinyImage from '../../components/TinyImage';
import type { Workshop } from '../../types';
import { smallestHeadingTextSx } from '../../utils/typography';

type Props = {
  workshop: Workshop;
};

const WorkshopInfo = ({ workshop: { description, infos, title } }: Props) => (
  <div
    sx={{
      textAlign: 'left',
      '& + &': { borderTop: 'solid 1px', marginTop: 7, paddingTop: 7 },
    }}
  >
    <h3 sx={{ fontSize: 4, marginBottom: 3 }}>{title}</h3>
    <TextContent content={description.text} />
    {description.author && (
      <p
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginTop: 2,
        }}
      >
        {description.author.image && (
          <TinyImage
            imageDimension="dynamic"
            imageInfo={description.author.image}
            imageShape="circle"
            style={{ marginRight: 3, marginTop: 2 }}
          />
        )}
        —
        <TextContent
          components={{ p: 'span' }}
          content={description.author.name}
          style={{
            ...smallestHeadingTextSx,
            fontWeight: 'body',
            display: 'inline',
            marginLeft: '0.5ch',
          }}
        />
      </p>
    )}
    <InfoList infos={infos} style={{ marginTop: 6 }} />
  </div>
);

export default WorkshopInfo;
