/** @jsxImportSource theme-ui */
import {
  ImageData,
  ImageLayout,
  ImageOffsetsX,
  ImageSettings,
} from '../../types';
import { containerSpacingSmallerSx } from '../../utils/spacing';
import ContentWithImageOnTheSide from '../../components/ContentWithImageOnTheSide';
import LinkButton from '../../components/LinkButton';
import TextContent from '../../components/TextContent';

type Props = {
  backgroundColor: string;
  description: string;
  events?: {
    place: string;
    times: { time: string }[];
  }[];
  image: ImageData | string;
  imageLayout: ImageLayout;
  imageOffsetsX?: ImageOffsetsX;
  imageOffsetY?: string;
  imageSettings?: ImageSettings;
  linkText?: string;
  linkUrl?: string;
  title: string;
};

const Promo = ({
  backgroundColor,
  description,
  events,
  image,
  imageLayout,
  imageOffsetsX,
  imageOffsetY,
  imageSettings,
  linkText,
  linkUrl,
  title,
}: Props) => (
  <li sx={{ marginBottom: 0 }}>
    <ContentWithImageOnTheSide
      backgroundColor={backgroundColor}
      image={image}
      imageLayout={imageLayout}
      imageOffsetsX={imageOffsetsX}
      imageOffsetY={imageOffsetY}
      imageSettings={imageSettings}
      yPosition="top"
    >
      <article
        sx={{
          marginLeft: imageLayout === 'left' ? 'auto' : 0,
          maxWidth: 'textPlusPadding',
          ...containerSpacingSmallerSx,
        }}
      >
        <h2>{title}</h2>
        <TextContent
          content={description}
          style={{ p: { marginBottom: '0.75rem' } }}
        />
        {events?.map(({ times, place }) => (
          <div
            sx={{
              fontFamily: 'heading',
              fontSize: 1,
              fontWeight: 'bold',
              marginTop: 3,
            }}
          >
            {times.map(({ time }) => (
              <TextContent key={time} content={time} />
            ))}
            <TextContent content={place} />
          </div>
        ))}
        {linkUrl && (
          <div sx={{ marginTop: 5 }}>
            <LinkButton to={linkUrl}>{linkText}</LinkButton>
          </div>
        )}
      </article>
    </ContentWithImageOnTheSide>
  </li>
);

export default Promo;
