/** @jsxImportSource theme-ui */
import type { ThemeUIStyleObject } from 'theme-ui';

import { smallestHeadingTextSx } from '../utils/typography';
import TextContent from './TextContent';

type Props = {
  credits: { credit: string }[];
  style?: ThemeUIStyleObject;
};

const Credits = ({ credits, style }: Props) => (
  <div
    sx={{
      textAlign: ['left', null, 'center'],
      ...smallestHeadingTextSx,
      ...style,
    }}
  >
    {credits.map((credit) => (
      <TextContent
        key={credit.credit}
        content={credit.credit}
        style={{ lineHeight: 'infoValue', maxWidth: null }}
      />
    ))}
  </div>
);

export default Credits;
