// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-club-page-tsx": () => import("./../../../src/templates/club-page.tsx" /* webpackChunkName: "component---src-templates-club-page-tsx" */),
  "component---src-templates-cookies-page-tsx": () => import("./../../../src/templates/cookies-page.tsx" /* webpackChunkName: "component---src-templates-cookies-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-press-page-tsx": () => import("./../../../src/templates/press-page.tsx" /* webpackChunkName: "component---src-templates-press-page-tsx" */),
  "component---src-templates-tours-page-tsx": () => import("./../../../src/templates/tours-page.tsx" /* webpackChunkName: "component---src-templates-tours-page-tsx" */),
  "component---src-templates-videos-page-tsx": () => import("./../../../src/templates/videos-page.tsx" /* webpackChunkName: "component---src-templates-videos-page-tsx" */),
  "component---src-templates-workshops-page-tsx": () => import("./../../../src/templates/workshops-page.tsx" /* webpackChunkName: "component---src-templates-workshops-page-tsx" */)
}

