/** @jsxImportSource theme-ui */
import ContentWithImageOnTheSide from '../../components/ContentWithImageOnTheSide';
import TextContent from '../../components/TextContent';
import { ImageData, ImageOffsetsX, ImageSettings, LangKey } from '../../types';
import { containerSpacingSx } from '../../utils/spacing';
import { screenReadersOnlySx } from '../../utils/screen-readers';

type Props = {
  content: string;
  image: ImageData;
  imageOffsetsX: ImageOffsetsX;
  imageOffsetY?: string;
  imageSettings?: ImageSettings;
  langKey: LangKey;
  title: string;
};

const Tours = ({
  content,
  image,
  imageOffsetsX,
  imageOffsetY,
  imageSettings,
  langKey,
  title,
}: Props) => (
  <section sx={{ backgroundColor: 'blackPure' }}>
    <ContentWithImageOnTheSide
      backgroundColor="black"
      image={image}
      imageLayout="right"
      imageOffsetsX={imageOffsetsX}
      imageOffsetY={imageOffsetY}
      imageSettings={imageSettings}
      yPosition="top"
    >
      <div
        sx={{
          marginRight: 'auto',
          maxWidth: 'textPlusPadding',
          ...containerSpacingSx,
        }}
      >
        <h1>{title}</h1>
        <h2 sx={screenReadersOnlySx}>
          {langKey === 'fi' ? 'Kuvaus' : 'Description'}
        </h2>
        <TextContent content={content} />
      </div>
    </ContentWithImageOnTheSide>
  </section>
);

export default Tours;
