/** @jsxImportSource theme-ui */
import { defaultHeight } from './defaults';
import type { IconProps } from './types';

const MapPin = ({ height = defaultHeight, style }: IconProps) => (
  <svg
    fill="currentColor"
    height={height}
    width={0.75 * height}
    viewBox="0 0 18 24"
    xmlns="http://www.w3.org/2000/svg"
    sx={style}
  >
    <g fillRule="evenodd">
      <path d="m9 13.5652174c2.2091 0 4-1.8687652 4-4.17391305 0-2.30518957-1.7909-4.17391305-4-4.17391305-2.20914 0-4 1.86872348-4 4.17391305 0 2.30514785 1.79086 4.17391305 4 4.17391305zm0-2.0869565c1.1046 0 2-.9343305 2-2.08695655 0-1.15259478-.8954-2.08695652-2-2.08695652s-2 .93436174-2 2.08695652c0 1.15262605.8954 2.08695655 2 2.08695655z" />
      <path d="m18 9.39130435c0 5.17878265-5.2948 11.00765215-7.762 13.44260865-.7036.6943305-1.7724.6943305-2.476 0-2.46717-2.4349565-7.762-8.263826-7.762-13.44260865 0-5.18667131 4.02944-9.39130435 9-9.39130435 4.9706 0 9 4.20463304 9 9.39130435zm-2 0c0 1.92365215-1.0207 4.23099135-2.5889 6.54553045-1.4677 2.1662609-3.2234 4.0622609-4.4111 5.2456695-1.1877-1.1834086-2.94339-3.0794086-4.41109-5.2456695-1.56816-2.3145391-2.58891-4.6218783-2.58891-6.54553045 0-4.03407652 3.13401-7.30434783 7-7.30434783 3.866 0 7 3.27027131 7 7.30434783z" />
    </g>
  </svg>
);

export default MapPin;
