import { graphql, PageProps } from 'gatsby';

import Layout from './layout/Layout';
import WorkshopsPageTemplate from './pages/workshops/WorkshopsPageTemplate';
import type {
  Events,
  Frontmatter,
  ImageData,
  ImageInfo,
  ImageOffsetsX,
  PageContext,
  PersonInfos,
  Workshop,
} from './types';

export type Props = PageProps<
  {
    markdownRemark: {
      frontmatter: Frontmatter & {
        image: ImageData;
        imageOffsetsX: ImageOffsetsX;
        imageOffsetY: string | null;
        logos: string[];
        pastEvents: Events;
        teachers: PersonInfos;
        workshops: {
          hide: boolean;
          items: Workshop[];
        };
        workshopImages: ImageInfo[];
      };
      rawMarkdownBody: string;
    };
  },
  PageContext
>;

const WorkshopsPage = ({ data, pageContext }: Props) => {
  const { frontmatter, rawMarkdownBody } = data.markdownRemark;

  return (
    <Layout
      langKey={pageContext.langKey}
      metadata={{
        description: frontmatter.meta.description,
        images: frontmatter.meta.images.map(({ image }) => image.publicURL),
        title: frontmatter.title,
      }}
      path={frontmatter.path}
      pathOtherLang={frontmatter.pathOtherLang}
    >
      <WorkshopsPageTemplate
        {...frontmatter}
        content={rawMarkdownBody}
        imageOffsetY={frontmatter.imageOffsetY ?? undefined}
        langKey={pageContext.langKey}
      />
    </Layout>
  );
};

export default WorkshopsPage;

export const pageQuery = graphql`
  query WorkshopsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      rawMarkdownBody
      fields {
        langKey
      }
      frontmatter {
        path
        pathOtherLang
        title
        meta {
          description
          images {
            image {
              publicURL
            }
          }
        }
        image {
          ...BackgroundImage
        }
        imageOffsetsX {
          _0_xs
          _1_sm
          _2_md
          _3_lg
          _4_xl
          _5_xxl
        }
        imageOffsetY
        logos
        workshops {
          hide
          items {
            title
            description {
              author {
                image {
                  alt
                  image {
                    childImageSharp {
                      tiny: gatsbyImageData(width: 100, height: 100)
                    }
                  }
                }
                name
              }
              text
            }
            infos {
              label
              values {
                icon
                text
              }
            }
          }
        }
        workshopImages {
          alt
          image {
            childImageSharp {
              gatsbyImageData(height: 225)
            }
          }
        }
        pastEvents {
          title
          items {
            date
            description
          }
        }
        teachers {
          title
          descriptions {
            description
            image {
              alt
              image {
                childImageSharp {
                  original {
                    width
                  }
                  tiny: gatsbyImageData(
                    width: 400
                    height: 400
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            name
          }
        }
      }
    }
  }
`;
