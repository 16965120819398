import { graphql, PageProps } from 'gatsby';

import Layout from './layout/Layout';
import ToursPageTemplate from './pages/tours/ToursPageTemplate';
import type {
  Events,
  Frontmatter,
  ImageData,
  ImageInfo,
  ImageOffsetsX,
  ImageSettings,
  InfoRollInfo,
  PageContext,
  Performances,
  Quotes,
} from './types';

export type Props = PageProps<
  {
    markdownRemark: {
      frontmatter: Frontmatter & {
        credits: { credit: string }[];
        events: Events;
        image: ImageData;
        imageOffsetsX: ImageOffsetsX;
        imageOffsetY?: string;
        imageSettings?: ImageSettings;
        info: InfoRollInfo;
        libraryTourImages: {
          credit: string;
          images?: ImageInfo[];
        };
        logos: string[];
        performances: Performances;
        quotes: Quotes;
      };
      rawMarkdownBody: string;
    };
  },
  PageContext
>;

const ToursPage = ({ data, pageContext }: Props) => {
  const { frontmatter, rawMarkdownBody } = data.markdownRemark;

  return (
    <Layout
      langKey={pageContext.langKey}
      metadata={{
        description: frontmatter.meta.description,
        images: frontmatter.meta.images.map(({ image }) => image.publicURL),
        title: frontmatter.title,
      }}
      path={frontmatter.path}
      pathOtherLang={frontmatter.pathOtherLang}
    >
      <ToursPageTemplate
        {...frontmatter}
        content={rawMarkdownBody}
        langKey={pageContext.langKey}
      />
    </Layout>
  );
};

export default ToursPage;

export const pageQuery = graphql`
  query ToursPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      rawMarkdownBody
      fields {
        langKey
      }
      frontmatter {
        path
        pathOtherLang
        performances {
          hide
          title
          descriptions {
            title
            images {
              alt
              image {
                childImageSharp {
                  original {
                    width
                  }
                  narrow: gatsbyImageData(width: 400, height: 600)
                  wide: gatsbyImageData(width: 600, height: 400)
                  square: gatsbyImageData(width: 490, height: 490)
                }
              }
              shape
            }
            performer
            description
          }
        }
        quotes {
          title
          items {
            text
          }
        }
        title
        meta {
          description
          images {
            image {
              publicURL
            }
          }
        }
        events {
          title
          items {
            date
            description
          }
        }
        image {
          ...BackgroundImage
        }
        imageOffsetsX {
          _0_xs
          _1_sm
          _2_md
          _3_lg
          _4_xl
          _5_xxl
        }
        imageOffsetY
        imageSettings
        info {
          hide
          credits {
            credit
          }
          items {
            fieldGroup {
              label
              values {
                items {
                  text
                  link
                }
              }
            }
          }
        }
        libraryTourImages {
          credit
        }
        logos
      }
    }
  }
`;
