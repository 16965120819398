/** @jsxImportSource theme-ui */
import { Link } from 'gatsby';
import { headerFooterLinkSx } from '../utils/links';

type Props = {
  children: React.ReactNode;
  to: string;
};

const NavbarLink = ({ children, to }: Props) => (
  <Link
    to={to}
    sx={{
      ...headerFooterLinkSx,
      paddingTop: '2px',
      flexGrow: 0,
      fontSize: [1, null, 2],
    }}
  >
    {children}
  </Link>
);

export default NavbarLink;
