/** @jsxImportSource theme-ui */
import { defaultHeight } from './defaults';
import type { IconProps } from './types';

const Facebook = ({ height = defaultHeight, style, title }: IconProps) => (
  <svg
    stroke="currentColor"
    height={height}
    width={height}
    strokeWidth="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    sx={style}
  >
    {title && <title>{title}</title>}
    <path
      d="m17.73 6.27v-4.77h-1a7.64 7.64 0 0 0 -7.59 7.64v.95h-2.87v3.82h2.87v8.59h4.77v-8.59h2.86v-3.82h-2.86v-.95a2.86 2.86 0 0 1 2.86-2.87z"
      fill="none"
      strokeMiterlimit="10"
    />
  </svg>
);
export default Facebook;
