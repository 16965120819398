export * from './club';
export * from './image';
export * from './template';
export * from './performance';
export * from './person';
export * from './promo';
export * from './video';
export * from './workshop';

export type Events = {
  items: {
    date: string;
    description: string;
  }[];
  title: string;
};

export type IconName = 'cart' | 'download' | 'map-pin';

export type InfoListInfos = InfoListItem[];

export type InfoListItem = {
  label?: string;
  values: { icon?: IconName; text: string }[];
};

export type InfoRollInfo = {
  credits?: { credit: string }[];
  hide: boolean;
  items: {
    fieldGroup: InfoRollItem[];
  }[];
};

export type InfoRollItem = {
  label: string;
  values: InfoRollItemValue[];
};

export type InfoRollItemValue = {
  items: {
    icon?: IconName;
    link?: string;
    text: string;
    type?: InfoItemValueType;
  }[];
  logo?: string;
};

export type InfoItemValueType = 'emphasized' | 'secondary' | '';

export type LangKey = 'en' | 'fi';

export type Quotes = {
  title: string;
  items: { text: string }[];
};
