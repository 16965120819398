/** @jsxImportSource theme-ui */
import ContentWithImageOnTheSide from '../../components/ContentWithImageOnTheSide';
import LogoSection from '../../components/LogoSection';
import TextContent from '../../components/TextContent';
import type { ImageData, ImageOffsetsX, LangKey, VideoInfo } from '../../types';
import { containerSpacingSx } from '../../utils/spacing';
import { screenReadersOnlySx } from '../../utils/screen-readers';
import ZigZagRoll from './ZigZagRoll';

type Props = {
  content: string;
  image: ImageData;
  imageOffsetsX: ImageOffsetsX;
  imageOffsetY?: string;
  langKey: LangKey;
  logos: string[];
  title: string;
  videos: VideoInfo[];
};

const VideosPageTemplate = ({
  content,
  image,
  imageOffsetsX,
  imageOffsetY,
  langKey,
  logos,
  title,
  videos,
}: Props) => (
  <div>
    <section>
      <ContentWithImageOnTheSide
        yPosition="bottom"
        image={image}
        imageLayout="right"
        imageSettings="smallerHeight"
        imageOffsetsX={imageOffsetsX}
        imageOffsetY={imageOffsetY}
        imageOffsetYConstant
      >
        <div sx={{ maxWidth: 'container', ...containerSpacingSx }}>
          <h1>{title}</h1>
          <h2 sx={screenReadersOnlySx}>
            {langKey === 'fi' ? 'Kuvaus' : 'Description'}
          </h2>
          <TextContent content={content} />
        </div>
      </ContentWithImageOnTheSide>
    </section>
    <ZigZagRoll items={videos} langKey={langKey} />
    <LogoSection langKey={langKey} logos={logos} />
  </div>
);

export default VideosPageTemplate;
