import type { ThemeUIStyleObject } from 'theme-ui';

import theme from '../../gatsby-plugin-theme-ui';
import '../css/lite-yt-embed.css';
import { globalLinkStyle } from './links';

export const globalStyles: ThemeUIStyleObject = {
  a: globalLinkStyle(theme),
  blockquote: {
    margin: 0,
  },
  body: {
    backgroundColor: theme.colors?.background,
    color: theme.colors?.text,
    fontSize: theme.fontSizes?.[3],
    fontVariationSettings: '"wdth" 112.75',
  },
  html: {
    '@media (prefers-reduced-motion: no-preference)': {
      scrollBehavior: 'smooth',
    },
  },
  li: {
    '&:last-child': {
      marginBottom: 0,
    },
  },
};
