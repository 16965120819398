import { graphql, PageProps } from 'gatsby';

import Layout from './layout/Layout';
import PressPageTemplate from './pages/press/PressPageTemplate';
import type {
  Frontmatter,
  ImageInfo,
  OriginalImageData,
  PageContext,
} from './types';

export type Props = PageProps<
  {
    markdownRemark: {
      frontmatter: Frontmatter & {
        images: ImageInfo<OriginalImageData>[];
      };
      rawMarkdownBody: string;
    };
  },
  PageContext
>;

const PressPage = ({ data, pageContext }: Props) => {
  const { frontmatter, rawMarkdownBody } = data.markdownRemark;

  return (
    <Layout
      langKey={pageContext.langKey}
      metadata={{
        description: frontmatter.meta.description,
        title: frontmatter.title,
      }}
      path={frontmatter.path}
      pathOtherLang={frontmatter.pathOtherLang}
    >
      <PressPageTemplate
        {...frontmatter}
        content={rawMarkdownBody}
        langKey={pageContext.langKey}
      />
    </Layout>
  );
};

export default PressPage;

export const pageQuery = graphql`
  query PressPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      rawMarkdownBody
      fields {
        langKey
      }
      frontmatter {
        path
        pathOtherLang
        title
        meta {
          description
        }
        images {
          alt
          description
          credit
          image {
            childImageSharp {
              original {
                src
                width
                height
              }
              parent {
                ... on File {
                  base
                  size
                }
              }
              gatsbyImageData(width: 300)
            }
          }
        }
      }
    }
  }
`;
