/** @jsxImportSource theme-ui */
import theme from '../../gatsby-plugin-theme-ui';
import type { ImageInfo, InfoRollInfo, LangKey } from '../types';
import { screenReadersOnlySx } from '../utils/screen-readers';
import { containerSpacingSx } from '../utils/spacing';
import { smallestHeadingTextSx } from '../utils/typography';
import Credits from './Credits';
import InfoValue from './InfoValue';
import List from './List';

type Props = {
  credits?: { credit: string }[];
  items: InfoRollInfo['items'];
  langKey: LangKey;
  logos?: { [name: string]: (ImageInfo & { langKey: LangKey })[] };
};

const InfoFieldColumns = ({
  items,
  langKey,
  logos,
}: Omit<Props, 'credits'>) => (
  <>
    {items.flatMap(({ fieldGroup }, index) => (
      <div key={index}>
        {fieldGroup.map((info) => (
          <li
            key={info.label}
            sx={{ flexBasis: '15rem', '& + &': { marginTop: 5 } }}
          >
            <h3
              sx={{
                fontSize: 1,
                lineHeight: 'infoValue',
                marginBottom: 2,
                textTransform: 'uppercase',
              }}
            >
              {info.label}
            </h3>
            <List style={smallestHeadingTextSx}>
              {info.values.map((value) => (
                <InfoValue
                  key={value.items[0].text}
                  langKey={langKey}
                  logos={logos}
                  value={value}
                />
              ))}
            </List>
          </li>
        ))}
      </div>
    ))}
  </>
);

const InfoRoll = ({ credits, items, langKey, logos }: Props) => (
  <div sx={{ px: containerSpacingSx.px, textAlign: 'center' }}>
    <h2 sx={screenReadersOnlySx}>{langKey === 'fi' ? 'Tiedot' : 'Details'}</h2>
    <List
      style={{
        display: ['grid', null, 'inline-grid'],
        textAlign: 'left',
        gridTemplateColumns: 'minmax(0, 1fr)',
        columnGap: [3, null, 6, 7, 8, 9],
        rowGap: 5,
        [`@media(min-width: ${theme.breakpoints?.[1]})`]: {
          gridTemplateColumns: 'repeat(2, minmax(min-content, 50%))',
        },
        [`@media(min-width: ${theme.breakpoints?.[2]})`]: {
          gridTemplateColumns: 'repeat(3, minmax(0, max-content))',
        },
      }}
    >
      <InfoFieldColumns items={items} langKey={langKey} logos={logos} />
    </List>
    {credits && credits.length > 0 && (
      <Credits
        credits={credits}
        style={{ marginTop: containerSpacingSx.paddingTop }}
      />
    )}
  </div>
);

export default InfoRoll;
