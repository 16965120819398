/** @jsxImportSource theme-ui */
import { Container, ThemeUIStyleObject } from 'theme-ui';

import {
  backgroundSecondarySplit,
  backgroundWaveContainerSx,
  backgroundWaveSx,
} from '../utils/backgrounds';
import { containerSpacingSx } from '../utils/spacing';

type Props = {
  as?: React.ElementType;
  children: React.ReactNode;
  containerStyle?: ThemeUIStyleObject;
  variant: SectionVariant;
};

type SectionVariant =
  | 'black'
  | 'blackPure'
  | 'blue'
  | 'blueDark'
  | 'purple'
  | 'purpleWithWave';

const sectionSx: Record<SectionVariant, ThemeUIStyleObject> = {
  black: { backgroundColor: 'black' },
  blackPure: { backgroundColor: 'blackPure' },
  blue: { textAlign: 'center' },
  blueDark: { backgroundColor: 'backgroundDark', textAlign: 'center' },
  purple: { backgroundColor: 'secondary' },
  purpleWithWave: {
    backgroundColor: 'secondary',
    backgroundImage: [null, null, null, backgroundSecondarySplit],
  },
};

const containerSx: Record<SectionVariant, ThemeUIStyleObject> = {
  black: {
    maxWidth: 'main',
  },
  blackPure: {
    maxWidth: 'main',
  },
  blue: {
    maxWidth: 'main',
  },
  blueDark: {},
  purple: {
    maxWidth: 'main',
  },
  purpleWithWave: {
    ...backgroundWaveSx,
    ...backgroundWaveContainerSx,
  },
};

const Section = ({
  as = 'section',
  children,
  containerStyle,
  variant,
}: Props) => {
  const Root = as;
  return (
    <Root sx={sectionSx[variant]}>
      <Container
        sx={{
          ...containerSx[variant],
          ...containerSpacingSx,
          ...containerStyle,
        }}
      >
        {children}
      </Container>
    </Root>
  );
};

export default Section;
