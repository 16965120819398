/** @jsxImportSource theme-ui */
import type * as CSS from 'csstype';
import type { ImageInfo, LangKey } from '../types';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import TextContent from './TextContent';
import YouTubeVideo from './YouTubeVideo';

type Props = {
  alignment?: CSS.Property.TextAlign;
  description: string;
  duration: string;
  id?: string;
  image: ImageInfo;
  langKey: LangKey;
  orientation: 'landscape' | 'portrait';
  title: string;
};

const Video = ({
  alignment = 'left',
  description,
  duration,
  id,
  image,
  langKey,
  orientation = 'portrait',
  title,
}: Props) => {
  const isPortrait = orientation === 'portrait' || !id;
  const videoWidth = isPortrait ? 400 : 800;
  const videoHeight = isPortrait ? 500 : 450;
  const responsiveValue = (stackedValue: any, portraitValue: any) => [
    stackedValue,
    null,
    null,
    isPortrait ? portraitValue : null,
  ];
  return (
    <div sx={{ textAlign: alignment }}>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: responsiveValue('column', 'row'),
          textAlign: responsiveValue('center', 'left'),
          maxWidth: responsiveValue(videoWidth, 2 * videoWidth),
          margin: 'auto',
        }}
      >
        {id ? (
          <YouTubeVideo
            style={{ flexBasis: responsiveValue('auto', '50%') }}
            videoHeight={videoHeight}
            videoId={id}
            videoWidth={videoWidth}
          />
        ) : (
          <PreviewCompatibleImage
            imageInfo={image}
            style={{
              boxShadow: 'lg',
              flexBasis: responsiveValue('auto', '50%'),
            }}
          />
        )}
        <div
          sx={{
            flexBasis: responsiveValue('auto', '50%'),
            paddingTop: responsiveValue(3, 0),
            paddingLeft: responsiveValue(0, 4),
            textAlign: 'left',
            maxWidth: videoWidth,
            width: '100%',
          }}
        >
          <h3 sx={{ marginBottom: responsiveValue(2, 3) }}>{title}</h3>
          <TextContent
            content={description}
            style={{ lineHeight: 'shortText' }}
          />
          <div sx={{ fontSize: 2, marginTop: responsiveValue(1, 2) }}>
            {langKey === 'fi' ? 'Kesto ' : 'Duration '}
            {duration}
          </div>
          {id ? null : (
            <div
              sx={{
                fontFamily: 'heading',
                fontSize: 2,
                fontWeight: 'heading',
                marginTop: responsiveValue(1, 2),
                textTransform: 'uppercase',
              }}
            >
              {langKey === 'fi' ? 'Tulossa' : 'Coming'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Video;
