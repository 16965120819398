/** @jsxImportSource theme-ui */
import type { ThemeUIStyleObject } from 'theme-ui';

import Facebook from '../icons/Facebook';
import Instagram from '../icons/Instagram';
import Twitter from '../icons/Twitter';
import YouTube from '../icons/YouTube';
import { LangKey } from '../types';
import SocialMediaLink from './SocialMediaLink';

type Props = {
  langKey: LangKey;
  style?: ThemeUIStyleObject;
};

const SocialMediaLinks = ({ langKey, style }: Props) => (
  <div
    sx={{
      display: 'flex',
      justifyContent: 'center',
      ...style,
      '> * + *': {
        marginLeft: 5,
      },
    }}
  >
    <SocialMediaLink to="https://www.instagram.com/auraco_dancemime/">
      <Instagram
        height={24}
        title={
          langKey === 'fi' ? 'Auraco Instagramissa' : 'Auraco on Instagram'
        }
      />
    </SocialMediaLink>
    <SocialMediaLink to="https://www.facebook.com/AuracoDanceMime/">
      <Facebook
        height={24}
        title={langKey === 'fi' ? 'Auraco Facebookissa' : 'Auraco on Facebook'}
      />
    </SocialMediaLink>
    <SocialMediaLink to="https://twitter.com/AuracoDance">
      <Twitter
        height={24}
        title={
          langKey === 'fi'
            ? 'Auraco X-palvelussa (entinen Twitter)'
            : 'Auraco on X (formerly Twitter)'
        }
      />
    </SocialMediaLink>
    <SocialMediaLink to="https://www.youtube.com/user/AuracoDance">
      <YouTube
        height={24}
        title={langKey === 'fi' ? 'Auraco YouTubessa' : 'Auraco on Youtube'}
      />
    </SocialMediaLink>
  </div>
);

export default SocialMediaLinks;
