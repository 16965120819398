/** @jsxImportSource theme-ui */
import type { ThemeUIStyleObject } from 'theme-ui';

import { percentage } from '../utils/calculations';

type Props = {
  style: ThemeUIStyleObject;
  videoHeight: number;
  videoId: string;
  videoWidth: number;
};

const YouTubeVideo = ({
  style,
  videoHeight = 500,
  videoId,
  videoWidth = 400,
}: Props) => (
  <div
    className="video-embedded-wrapper"
    sx={{ width: '100%', maxWidth: videoWidth, ...style }}
  >
    <div
      className="video-embedded"
      sx={{
        boxShadow: 'lg',
        position: 'relative',
        paddingTop: percentage(videoHeight, videoWidth),
      }}
    >
      {/* @ts-expect-error lite-youtube is an unrecognised web component */}
      <lite-youtube
        videoid={videoId}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  </div>
);

export default YouTubeVideo;
