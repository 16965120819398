/** @jsxImportSource theme-ui */
import { buttonSx } from '../utils/buttons';

type Props = {
  children: React.ReactNode;
  onClick: () => void;
};

const Button = ({ children, onClick }: Props) => (
  <button onClick={onClick} sx={buttonSx} type="button">
    {children}
  </button>
);

export default Button;
