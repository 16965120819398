/** @jsxImportSource theme-ui */
import { ThemeUIStyleObject } from 'theme-ui';

import type { InfoListInfos } from '../types';
import Icon from './Icon';
import List from './List';
import TextContent from './TextContent';

type Props = {
  infos: InfoListInfos;
  style?: ThemeUIStyleObject;
};

const InfoList = ({ infos, style }: Props) => (
  <List style={style}>
    {infos.map(({ label, values }, index) => (
      <li key={label || index} sx={{ display: 'flex' }}>
        {label && (
          <div sx={{ fontWeight: 'bold', marginRight: '0.5ch' }}>{label}:</div>
        )}
        <div sx={{ display: 'flex', flexDirection: 'column' }}>
          {values.map(({ icon, text }) => (
            <div key={text} style={{ alignItems: 'center', display: 'flex' }}>
              {icon && <Icon name={icon} style={{ marginRight: 2 }} />}
              <TextContent
                components={{ p: 'span' }}
                content={text}
                style={{ display: 'inline' }}
              />
            </div>
          ))}
        </div>
      </li>
    ))}
  </List>
);

export default InfoList;
