/** @jsxImportSource theme-ui */
import { Link } from 'gatsby';

import LinkExternal from './LinkExternal';

type Props = {
  children: React.ReactNode;
  href?: string;
};

const LinkInMarkdown = ({ children, href }: Props) => {
  if (!href) {
    return children;
  }
  return /^https?:/.test(href) ? (
    <LinkExternal href={href}>{children}</LinkExternal>
  ) : (
    <Link to={href}>{children}</Link>
  );
};

export default LinkInMarkdown;
