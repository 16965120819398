/** @jsxImportSource theme-ui */
import theme from '../../gatsby-plugin-theme-ui';
import useSiteMetadata from '../hooks/useSiteMetadata';
import type { LangKey } from '../types';
import { getLangPath } from '../utils/languages';
import FooterLink from './FooterLink';
import SocialMediaLinks from './SocialMediaLinks';

type Props = {
  langKey: LangKey;
};

const Footer = ({ langKey }: Props) => {
  const { buildDate } = useSiteMetadata();
  const basePath = getLangPath(langKey);
  return (
    <footer
      sx={{
        backgroundColor: 'white',
        color: 'gray',
        fontFamily: 'heading',
        fontSize: [0, 1],
      }}
    >
      <div
        sx={{
          maxWidth: 'footer',
          mx: 'auto',
          padding: [3, 4],
        }}
      >
        <SocialMediaLinks
          langKey={langKey}
          style={{
            marginBottom: 1,
            [`@media(min-width: ${theme.breakpoints?.[2]})`]: {
              display: 'none',
            },
          }}
        />
        <div
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <FooterLink
              isInternal
              to={`${basePath}/${langKey === 'fi' ? 'pressi/' : 'press/'}`}
            >
              {langKey === 'fi' ? 'Pressi' : 'Press'}
            </FooterLink>
            <FooterLink
              isInternal
              to={`${basePath}/${langKey === 'fi' ? 'evasteet/' : 'cookies/'}`}
            >
              {langKey === 'fi' ? 'Evästeet' : 'Cookies'}
            </FooterLink>
          </div>
          <SocialMediaLinks
            langKey={langKey}
            style={{
              display: 'none',
              marginTop: 1,
              [`@media(min-width: ${theme.breakpoints?.[2]})`]: {
                display: 'flex',
              },
            }}
          />
          <span>© {new Date(buildDate).getFullYear()} Kuukulkurit ry</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
