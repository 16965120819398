/** @jsxImportSource theme-ui */
import type { LangKey } from '../types';
import { delayedFadeInAnimation } from '../utils/animations';
import { lightBackgroundLinkColorSx } from '../utils/links';
import Button from './Button';
import TextContent from './TextContent';

type Props = {
  langKey: LangKey;
  onResponse: (givesConsent: boolean) => void;
};

const description: Record<LangKey, string> = {
  en: 'We use cookies to analyse how our site is used. Learn more in our [cookie policy](/en/cookies/).',
  fi: 'Käytämme evästeitä sivuston käytön analysoimiseksi. Lue lisää [evästeiden käytöstämme](/evasteet/).',
};

const buttonLabels: Record<LangKey, { yes: string; no: string }> = {
  en: { yes: 'OK', no: 'No, thanks' },
  fi: { yes: 'OK', no: 'Ei kiitos' },
};

const CookieBanner = ({ langKey, onResponse }: Props) => (
  <div
    sx={{
      animation: delayedFadeInAnimation,
      opacity: 0,
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      bottom: 0,
      right: 0,
      backgroundColor: 'white',
      color: 'black',
      margin: [1, null, 2, null, 3],
      padding: [3, null, 4, null, 5],
      boxShadow: '2xl',
      borderColor: 'black',
      borderRadius: 1,
      borderStyle: 'solid',
      borderWidth: 2,
    }}
  >
    <TextContent
      content={description[langKey]}
      style={{
        lineHeight: 'bannerText',
        maxWidth: 'none',
        ...lightBackgroundLinkColorSx,
      }}
    />
    <div sx={{ flexShrink: 0, marginLeft: 5, '> * + *': { marginLeft: 3 } }}>
      <Button onClick={() => onResponse(true)}>
        {buttonLabels[langKey].yes}
      </Button>
    </div>
  </div>
);

export default CookieBanner;
