/** @jsxImportSource theme-ui */
import type { FunctionComponent } from 'react';
import ReactMarkDown, { Options } from 'react-markdown';
import type { ThemeUIStyleObject } from 'theme-ui';

import LinkInMarkdown from './LinkInMarkdown';

type Props = {
  components?: Options['components'];
  content: string;
  style?: ThemeUIStyleObject;
};

const TextContent = ({ components, content, style }: Props) => (
  <ReactMarkDown
    components={{ a: LinkInMarkdown as FunctionComponent, ...components }}
    sx={{ maxWidth: 'text', '& p:last-child': { marginBottom: 0 }, ...style }}
  >
    {content}
  </ReactMarkDown>
);

export default TextContent;
