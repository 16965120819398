/** @jsxImportSource theme-ui */
import { getSrc, IGatsbyImageData } from 'gatsby-plugin-image';
import type { ThemeUIStyleObject } from 'theme-ui';

import { backgroundImageWidth } from '../fragments/image';
import type {
  ImageData,
  ImageLayout,
  ImageOffsetsX,
  ImageSettings,
} from '../types';

type ImageVariants = {
  left?: IGatsbyImageData;
  right?: IGatsbyImageData;
  rightSquare?: IGatsbyImageData;
  smallerHeight?: IGatsbyImageData;
};

type Props = {
  backgroundColor?: string;
  children: React.ReactNode;
  image?: ImageData<ImageVariants> | string;
  imageBackgroundColor?: string;
  imageLayout?: ImageLayout;
  imageOffsetsX?: ImageOffsetsX;
  imageOffsetY?: string;
  imageOffsetYConstant?: boolean;
  imageSettings?: ImageSettings;
  style?: ThemeUIStyleObject;
  yPosition: string;
};

function getImageLayoutProperties(yPosition: string, imageLayout: ImageLayout) {
  const isLeft = imageLayout === 'left';
  return {
    imagePositionX: imageLayout,
    imagePositionY: yPosition,
    textGradientDirection: isLeft ? 'right' : 'left',
    textPosition: isLeft ? 'flex-end' : 'flex-start',
  };
}

function getImageProperties(
  imageLayout: ImageLayout,
  imageSettings?: ImageSettings,
  image?: ImageData<ImageVariants> | string,
) {
  if (!image) {
    return { size: '', url: '' };
  }
  if (typeof image === 'string') {
    return { size: `${backgroundImageWidth / 2}px`, url: image };
  }
  const fluidImage = imageSettings
    ? image?.childImageSharp[imageSettings]
    : image?.childImageSharp[imageLayout];
  return {
    size: fluidImage
      ? `${fluidImage.width / 2}px`
      : `${backgroundImageWidth / 2}px`,
    url: fluidImage ? getSrc(fluidImage) : '',
  };
}

function getImageOffsetX(
  imageOffsetsX: { [key: string]: string | null },
  breakpoint: string,
) {
  const [, offset] =
    Object.entries(imageOffsetsX).find(([key]) =>
      key.includes(`_${breakpoint}`),
    ) ?? [];
  return offset || '';
}

const defaultImageOffsetsX = {
  _0_xs: null,
  _1_sm: null,
  _2_md: null,
  _3_lg: null,
  _4_xl: null,
  _5_xxl: null,
};

const ContentWithImageOnTheSide = ({
  backgroundColor = '#010066',
  children,
  image,
  imageBackgroundColor = 'black',
  imageLayout = 'left',
  imageOffsetsX = defaultImageOffsetsX,
  imageOffsetY = '',
  imageOffsetYConstant = false,
  imageSettings,
  style,
  yPosition,
}: Props) => {
  const layoutProps = getImageLayoutProperties(yPosition, imageLayout);
  const imageProps = getImageProperties(imageLayout, imageSettings, image);
  return (
    <div
      // Note: define the same amount of responsive values in all properties of an elemnt,
      // otherwise the styles may be written in the wrong order
      sx={{
        backgroundColor: [
          imageBackgroundColor,
          null,
          null,
          null,
          'transparent',
          null,
        ],
        backgroundImage: `url(${imageProps.url})`,
        backgroundPosition: [
          `${layoutProps.imagePositionX} ${getImageOffsetX(
            imageOffsetsX,
            'xs',
          )} ${
            imageOffsetY ? `top ${imageOffsetY}` : layoutProps.imagePositionY
          }`,
          `${layoutProps.imagePositionX} ${getImageOffsetX(
            imageOffsetsX,
            'sm',
          )} ${
            imageOffsetY ? `top ${imageOffsetY}` : layoutProps.imagePositionY
          }`,
          `${layoutProps.imagePositionX} ${getImageOffsetX(
            imageOffsetsX,
            'md',
          )} ${
            imageOffsetY ? `top ${imageOffsetY}` : layoutProps.imagePositionY
          }`,
          `${layoutProps.imagePositionX} ${getImageOffsetX(
            imageOffsetsX,
            'lg',
          )} ${
            imageOffsetYConstant
              ? `top ${imageOffsetY}`
              : `${layoutProps.imagePositionY} ${imageOffsetY}`
          }`,
          `${layoutProps.imagePositionX} ${getImageOffsetX(
            imageOffsetsX,
            'xl',
          )} ${
            imageOffsetYConstant
              ? `top ${imageOffsetY}`
              : `${layoutProps.imagePositionY} ${imageOffsetY}`
          }`,
          `${layoutProps.imagePositionX} ${getImageOffsetX(
            imageOffsetsX,
            'xxl',
          )} ${
            imageOffsetYConstant
              ? `top ${imageOffsetY}`
              : `${layoutProps.imagePositionY} ${imageOffsetY}`
          }`,
        ],
        backgroundRepeat: 'no-repeat',
        backgroundSize: imageProps.size,
        margin: 'auto',
        maxWidth: 'main',
        ...style,
      }}
    >
      <div
        sx={{
          backgroundColor: ['semiTransparent', null, null, null, 'transparent'],
          maxWidth: 'main',
          display: 'flex',
          margin: 'auto',
          flexDirection: 'column',
          // Fix occasional one pixel gap on mobile Safari, inspired by https://stackoverflow.com/a/6324025/305436
          borderTop: ['1px solid transparent', null, null, null, 'none'],
        }}
      >
        <div
          sx={{
            backgroundImage: [
              null,
              null,
              null,
              null,
              `linear-gradient(to ${layoutProps.textGradientDirection}, transparent 0%, ${backgroundColor} 75%)`,
              `linear-gradient(to ${layoutProps.textGradientDirection}, transparent 25%, ${backgroundColor} 70%)`,
            ],
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ContentWithImageOnTheSide;
