import type { IGatsbyImageData } from 'gatsby-plugin-image';

import type { AnyImageData, ImageData, ImageShape } from '../types';

export function getImageData(
  image: ImageData<AnyImageData>,
  imageShape?: ImageShape,
): IGatsbyImageData | undefined {
  const { childImageSharp } = image;

  if (imageShape && childImageSharp[imageShape]) {
    return childImageSharp[imageShape];
  }
  if (childImageSharp?.gatsbyImageData) {
    return childImageSharp.gatsbyImageData;
  }
  if (childImageSharp?.originalSize) {
    return childImageSharp?.originalSize;
  }
  if (
    childImageSharp?.original &&
    childImageSharp?.square &&
    childImageSharp.original.width === childImageSharp.original.height
  ) {
    return childImageSharp.square;
  }
  if (
    childImageSharp?.original &&
    childImageSharp?.narrow &&
    childImageSharp?.wide
  ) {
    // Show wide image if the original is at least twice as wide, otherwise narrow
    return childImageSharp.original.width >= 2 * childImageSharp.wide.width
      ? childImageSharp.wide
      : childImageSharp.narrow;
  }
  return (
    childImageSharp?.narrow || childImageSharp?.tiny || childImageSharp?.wide
  );
}

export function getImageDimension(
  image: ImageData<AnyImageData>,
  dimension: 'height' | 'width',
  imageShape?: ImageShape,
): number {
  if (image.childImageSharp?.original && image.childImageSharp?.originalSize) {
    return image.childImageSharp.original[dimension] / 2;
  }
  const dimensionSize = getImageData(image, imageShape)?.[dimension] || 0;
  const originalDimensionSize =
    (image.childImageSharp?.original?.[dimension] || 0) / 2;
  // Limit dimension to original dimension size to avoid blurry images
  return originalDimensionSize
    ? Math.min(dimensionSize, originalDimensionSize)
    : dimensionSize;
}

export function getImageHeight(
  image: ImageData<AnyImageData>,
  imageShape?: ImageShape,
): number {
  return getImageDimension(image, 'height', imageShape);
}

export function getImageWidth(
  image: ImageData<AnyImageData>,
  imageShape?: ImageShape,
): number {
  return getImageDimension(image, 'width', imageShape);
}
