/** @jsxImportSource theme-ui */
import type * as CSS from 'csstype';

import type { ImageInfo } from '../types';
import { itemSpacingSx } from '../utils/spacing';
import { smallHeadingTextSx } from '../utils/typography';
import LinkExternal from './LinkExternal';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import TextContent from './TextContent';

type Props = {
  alignment: CSS.Property.TextAlign;
  description: string;
  images: ImageInfo[];
  performer: string;
  teaser?: string;
  title: string;
};

const Performance = ({
  alignment = 'left',
  description,
  images,
  performer,
  teaser,
  title,
}: Props) => (
  <div
    sx={{
      marginX: 'auto',
      maxWidth: images[0]?.shape === 'narrow' ? 'containerWidest' : undefined,
      textAlign: alignment,
      ...itemSpacingSx,
    }}
  >
    <div
      sx={{
        display: 'inline-flex',
        flexDirection: ['column', 'column', null, 'row'],
        maxWidth: '100%',
        textAlign: 'left',
        '> *': {
          minWidth: 0,
        },
      }}
    >
      {images[0] && (
        <div
          sx={{
            paddingTop: '3px',
            paddingRight: [null, null, null, 3],
            paddingBottom: [2, null, null, 0],
          }}
        >
          <PreviewCompatibleImage
            imageInfo={images[0]}
            style={{ boxShadow: 'lg' }}
          />
        </div>
      )}
      <div>
        <h3
          sx={{
            fontSize: 4,
            marginTop: 1,
            marginBottom: 1,
          }}
        >
          {title}
        </h3>
        <div
          sx={{
            marginRight: teaser ? 3 : 0,
            ...smallHeadingTextSx,
          }}
        >
          {performer}
        </div>
        {teaser && (
          <span sx={smallHeadingTextSx}>
            <LinkExternal
              href={teaser}
              style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              teaser
            </LinkExternal>
          </span>
        )}
        <TextContent
          content={description}
          style={{ marginTop: 3, maxWidth: 'textNarrow' }}
        />
        {images[1] && (
          <PreviewCompatibleImage
            imageInfo={images[1]}
            style={{ boxShadow: 'lg', marginTop: 3 }}
          />
        )}
      </div>
    </div>
  </div>
);

export default Performance;
