/** @jsxImportSource theme-ui */
import type {
  ImageInfo,
  InfoRollItemValue,
  InfoItemValueType,
  LangKey,
} from '../types';
import Icon from './Icon';
import LinkInternalOrExternal from './LinkInternalOrExternal';
import PreviewCompatibleImage from './PreviewCompatibleImage';

type Props = {
  langKey?: string;
  logos?: { [name: string]: (ImageInfo & { langKey: LangKey })[] };
  value: InfoRollItemValue;
};

const itemStyle = ({ type }: { type?: InfoItemValueType }) => ({
  fontStyle: type === 'secondary' ? 'italic' : null,
  fontWeight: type === 'emphasized' ? 'body' : null,
});

const InfoValue = ({ langKey, logos = {}, value }: Props) => {
  const logo = value.logo
    ? logos[value.logo].find((item) => item.langKey === langKey)
    : null;
  return (
    <li
      key={value.items[0].text}
      sx={{ lineHeight: 'infoValue', marginBottom: 2 }}
    >
      {logo && (
        <PreviewCompatibleImage
          imageInfo={logo}
          style={{ display: 'inline-block', marginTop: '4px' }}
        />
      )}
      {value.items.map((item) => (
        <div key={item.text} style={{ alignItems: 'center', display: 'flex' }}>
          {item.icon && <Icon name={item.icon} style={{ marginRight: 2 }} />}
          {item.link ? (
            <LinkInternalOrExternal href={item.link} style={itemStyle(item)}>
              {item.text}
            </LinkInternalOrExternal>
          ) : (
            <div sx={itemStyle(item)}>{item.text}</div>
          )}
        </div>
      ))}
    </li>
  );
};

export default InfoValue;
