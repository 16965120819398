/** @jsxImportSource theme-ui */
import { useCallback } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { ImageInfo } from '../types';
import { getImageDimension } from '../utils/images';
import PreviewCompatibleImage from './PreviewCompatibleImage';

type Props = {
  imageDimension: 'dynamic' | number;
  imageInfo: ImageInfo;
  imageShape: 'circle' | 'roundedRectangle';
  style?: ThemeUIStyleObject;
};

const TinyImage = ({ imageDimension, imageInfo, imageShape, style }: Props) => {
  const borderRadius = imageShape === 'circle' ? '50%' : undefined;

  const getDimension = useCallback(
    (dimension: 'height' | 'width') => {
      const dimensionSize =
        imageDimension === 'dynamic' && typeof imageInfo.image !== 'string'
          ? getImageDimension(imageInfo.image, dimension)
          : (imageDimension as number);
      return [`${0.75 * dimensionSize}px`, null, null, `${dimensionSize}px`];
    },
    [imageInfo],
  );
  const height = getDimension('height');
  const width = getDimension('width');

  return (
    <PreviewCompatibleImage
      imageInfo={imageInfo}
      imgStyle={{ borderRadius }} // for Safari
      style={{
        borderRadius,
        boxShadow: 'xl',
        height,
        width,
        ...style,
      }}
    />
  );
};

export default TinyImage;
