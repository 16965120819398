/** @jsxImportSource theme-ui */
import type { CSSProperties } from 'react';
import type { ThemeUIStyleObject } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

import type { ImageInfo } from '../types';
import { getImageData, getImageWidth } from '../utils/images';

type Props = {
  imageInfo: ImageInfo;
  imgStyle?: CSSProperties;
  style?: ThemeUIStyleObject;
};

const PreviewCompatibleImage = ({ imageInfo, imgStyle, style }: Props) => {
  const { alt, image, shape } = imageInfo;

  if (!image) {
    return null;
  }

  const baseImageStyle = {
    maxWidth: '100%',
    ...style,
  };

  if (typeof image === 'string') {
    return <img sx={baseImageStyle} src={image} alt={alt} />;
  }

  const imageData = getImageData(image, shape);
  if (!imageData) {
    throw new Error(
      `No image data found for image with keys: ${Object.keys(image).join(
        ', ',
      )}`,
    );
  }

  const imageStyle = {
    width: getImageWidth(image, shape),
    ...baseImageStyle,
  };
  return (
    <GatsbyImage
      image={imageData}
      imgStyle={imgStyle}
      sx={{ ...imageStyle }}
      alt={alt}
    />
  );
};

export default PreviewCompatibleImage;
