import { graphql, PageProps } from 'gatsby';

import Layout from './layout/Layout';
import IndexPageTemplate from './pages/index/IndexPageTemplate';
import type { Frontmatter, PageContext, PromoCard } from './types';

export type Props = PageProps<
  {
    markdownRemark: {
      frontmatter: Frontmatter & {
        promos: { cards: PromoCard[] };
      };
      rawMarkdownBody: string;
    };
  },
  PageContext
>;

const IndexPage = ({ data, pageContext }: Props) => {
  const { frontmatter, rawMarkdownBody } = data.markdownRemark;

  return (
    <Layout
      langKey={pageContext.langKey}
      metadata={{
        description: frontmatter.meta.description,
        images: frontmatter.meta.images.map(({ image }) => image.publicURL),
        title: frontmatter.title,
      }}
      path={frontmatter.path}
      pathOtherLang={frontmatter.pathOtherLang}
    >
      <IndexPageTemplate {...frontmatter} content={rawMarkdownBody} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate($langKey: String!) {
    markdownRemark(
      fields: { langKey: { eq: $langKey } }
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      rawMarkdownBody
      fields {
        langKey
      }
      frontmatter {
        path
        pathOtherLang
        title
        meta {
          description
          images {
            image {
              publicURL
            }
          }
        }
        promos {
          cards {
            backgroundColor
            title
            description
            readMoreLinkText
            linkUrl
            image {
              ...BackgroundImage
            }
            imageLayout
            imageOffsetsX {
              _0_xs
              _1_sm
              _2_md
              _3_lg
              _4_xl
              _5_xxl
            }
            imageOffsetY
            imageSettings
          }
        }
      }
    }
  }
`;
