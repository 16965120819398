import LogoSection from '../../components/LogoSection';
import EventRoll from '../../components/EventRoll';
import PersonRoll from '../../components/PersonRoll';
import Section from '../../components/Section';
import type {
  Events,
  ImageData,
  ImageInfo,
  ImageOffsetsX,
  LangKey,
  PersonInfos,
  Workshop,
} from '../../types';
import { containerSpacingSmallerSx } from '../../utils/spacing';
import WorkshopsHeader from './WorkshopsHeader';
import WorkshopInfo from './WorkshopInfo';

type Props = {
  content: string;
  image: ImageData;
  imageOffsetsX: ImageOffsetsX;
  imageOffsetY?: string;
  langKey: LangKey;
  logos: string[];
  pastEvents: Events;
  teachers: PersonInfos;
  title: string;
  workshops: {
    hide: boolean;
    items: Workshop[];
  };
  workshopImages: ImageInfo[];
};

const WorkshopsPageTemplate = ({
  content,
  image,
  imageOffsetsX,
  imageOffsetY,
  langKey,
  logos,
  pastEvents,
  teachers,
  title,
  workshops,
  workshopImages,
}: Props) => (
  <div>
    <WorkshopsHeader
      content={content}
      image={image}
      imageOffsetsX={imageOffsetsX}
      imageOffsetY={imageOffsetY}
      langKey={langKey}
      title={title}
      workshopImages={workshopImages}
    />
    {!workshops.hide && (
      <Section
        variant="blue"
        containerStyle={{
          maxWidth: 'textPlusPadding',
          paddingBottom: containerSpacingSmallerSx.py,
        }}
      >
        <h2>{langKey === 'en' ? 'Next workshops' : 'Seuraavat kurssit'}</h2>
        {workshops.items.map((workshop) => (
          <WorkshopInfo key={workshop.title} workshop={workshop} />
        ))}
      </Section>
    )}
    <Section variant="blue" containerStyle={{ maxWidth: 'text' }}>
      <EventRoll events={pastEvents} />
    </Section>
    <Section variant="purpleWithWave">
      <PersonRoll imageShape="circle" infos={teachers} />
    </Section>
    <LogoSection langKey={langKey} logos={logos} />
  </div>
);

export default WorkshopsPageTemplate;
