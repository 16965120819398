import type { ThemeUIStyleObject } from 'theme-ui';

// From 'sr-only' class in https://github.com/h5bp/html5-boilerplate/blob/e1c4afe46ab838e22236a3f475d1090c07f4de56/dist/css/main.css
export const screenReadersOnlySx: ThemeUIStyleObject = {
  border: 0,
  clip: 'rect(0, 0, 0, 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
};
