/** @jsxImportSource theme-ui */
import type { LangKey } from '../types';
import { easings, timings } from '../utils/animations';
import { linkButtonSx } from '../utils/links';

type Props = {
  langKey: LangKey;
};

const SkipToMainContent = ({ langKey }: Props) => (
  <a
    href="#main-content"
    onClick={() =>
      document.querySelector<HTMLElement>('#main-content')?.focus()
    }
    sx={{
      ...linkButtonSx,
      left: 1,
      opacity: 0,
      position: 'absolute',
      top: 2,
      transform: 'translateY(-64px)',
      transition: `opacity ${timings[2]} ${easings[0]}, transform ${timings[1]} ${easings[0]}`,
      zIndex: 1,

      '&:focus': {
        opacity: 1,
        transform: 'none',
      },
    }}
  >
    {langKey === 'fi' ? 'Siirry sisältöön' : 'Skip to main content'}
  </a>
);

export default SkipToMainContent;
