import type { ThemeUIStyleObject } from 'theme-ui';

import theme from '../../gatsby-plugin-theme-ui/theme';
import wave from '../img/wave-haikei.svg';

export const backgroundGradientTransparent =
  'radial-gradient(transparent, rgba(0, 0, 0, 0.25))';

export const backgroundSecondarySplit = `linear-gradient(90deg, ${theme.colors?.secondary} 50%, ${theme.colors?.secondaryDark} 50%)`;

export const backgroundWaveSx: ThemeUIStyleObject = {
  backgroundColor: 'secondary',
  backgroundImage: [
    null,
    null,
    null,
    `url(${wave}), ${backgroundSecondarySplit}`,
  ],
  backgroundPosition: [null, null, null, '50px, center', null],
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
};
export const backgroundWaveContainerSx: ThemeUIStyleObject = {
  maxWidth: ['text', null, null, 'containerWider'],
};
