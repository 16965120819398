/** @jsxImportSource theme-ui */
import { Link } from 'gatsby';
import type { ThemeUIStyleObject } from 'theme-ui';

import LinkExternal from './LinkExternal';

type Props = {
  children: React.ReactNode;
  href: string;
  style?: ThemeUIStyleObject;
};

const LinkInternalOrExternal = ({ children, href, style }: Props) =>
  /^https?:/.test(href) ? (
    <LinkExternal href={href} style={style}>
      {children}
    </LinkExternal>
  ) : (
    <Link sx={style} to={href}>
      {children}
    </Link>
  );

export default LinkInternalOrExternal;
