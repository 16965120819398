/** @jsxImportSource theme-ui */
import { linkButtonSx } from '../utils/links';
import LinkInternalOrExternal from './LinkInternalOrExternal';

type Props = {
  children: React.ReactNode;
  to: string;
};

const LinkButton = ({ children, to }: Props) => (
  <LinkInternalOrExternal style={linkButtonSx} href={to}>
    {children}
  </LinkInternalOrExternal>
);

export default LinkButton;
