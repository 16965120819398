/** @jsxImportSource theme-ui */
import type { PerformanceInfo } from '../types';
import Performance from './Performance';
import TextContent from './TextContent';

type Props = {
  credit?: string;
  performances: PerformanceInfo[];
  heading: string;
};

const PerformanceRoll = ({ credit, performances, heading }: Props) => (
  <>
    <h2 sx={{ textAlign: 'center', marginBottom: [6, null, null, 7] }}>
      {heading}
    </h2>
    {performances.map((performance, index) => (
      <Performance
        alignment={index % 2 === 1 ? 'right' : 'left'}
        description={performance.description}
        images={performance.images}
        key={performance.title}
        performer={performance.performer}
        title={performance.title}
      />
    ))}
    {credit && (
      <TextContent
        content={credit}
        style={{
          fontSize: 1,
          maxWidth: 'none',
          textAlign: 'center',
        }}
      />
    )}
  </>
);

export default PerformanceRoll;
