/** @jsxImportSource theme-ui */
import type { ThemeUIStyleObject } from 'theme-ui';

import { ImageInfo } from '../types';
import PreviewCompatibleImage from './PreviewCompatibleImage';

type Props = {
  images: ImageInfo[];
  style?: ThemeUIStyleObject;
};

const ImageRoll = ({ images, style }: Props) => (
  <div sx={{ display: 'flex', justifyContent: 'space-evenly', ...style }}>
    {images.map((image) => (
      <PreviewCompatibleImage
        imageInfo={image}
        key={image.alt}
        style={{ boxShadow: 'xl' }}
      />
    ))}
  </div>
);

export default ImageRoll;
