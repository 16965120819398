import { graphql, PageProps } from 'gatsby';

import Layout from './layout/Layout';
import CookiesPageTemplate from './pages/cookies/CookiesPageTemplate';
import type { Frontmatter, PageContext } from './types';

export type Props = PageProps<
  {
    markdownRemark: {
      frontmatter: Frontmatter;
      rawMarkdownBody: string;
    };
  },
  PageContext
>;

const CookiesPage = ({ data, pageContext }: Props) => {
  const { frontmatter, rawMarkdownBody } = data.markdownRemark;

  return (
    <Layout
      {...frontmatter}
      langKey={pageContext.langKey}
      metadata={{
        description: frontmatter.meta.description,
        title: frontmatter.title,
      }}
    >
      <CookiesPageTemplate content={rawMarkdownBody} />
    </Layout>
  );
};

export default CookiesPage;

export const pageQuery = graphql`
  query CookiesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      rawMarkdownBody
      fields {
        langKey
      }
      frontmatter {
        path
        pathOtherLang
        title
        meta {
          description
        }
      }
    }
  }
`;
