import { IGatsbyImageData } from 'gatsby-plugin-image';

// TODO: is this correct?
export type ImageData<T = {}> = {
  childImageSharp: {
    gatsbyImageData?: IGatsbyImageData;
  } & T;
};

export type ImageShape =
  | 'narrow'
  | 'original'
  | 'originalSize'
  | 'square'
  | 'tiny'
  | 'wide';

export type AnyImageData = {
  [key in ImageShape]: IGatsbyImageData;
};

type BaseImageInfo<T> = {
  alt: string;
  credit?: string;
  description?: string;
  descriptionPosition?: 'left' | 'right';
  image: T;
  shape?: ImageShape;
};
type ImageInfoWithData<T> = BaseImageInfo<ImageData<T>>;
type ImageInfoWithUrl = BaseImageInfo<string>;

export type OriginalImageData = {
  original: { height: number; src: string; width: number };
  parent: { base: string; size: number };
};

export type ImageInfo<T = {}> =
  | ImageInfoWithData<AnyImageData & T>
  | ImageInfoWithUrl;

export const hasImageData = (
  image: ImageInfo<OriginalImageData>,
): image is ImageInfoWithData<AnyImageData & OriginalImageData> =>
  typeof image !== 'string';

export type ImageLayout = 'left' | 'right';

export type ImageSettings = 'rightSquare' | 'smallerHeight';

export type ImageOffsetsX = {
  _0_xs: string | null;
  _1_sm: string | null;
  _2_md: string | null;
  _3_lg: string | null;
  _4_xl: string | null;
  _5_xxl: string | null;
};

export type Logo = {
  image: ImageInfo;
  link: string;
};

export type LogoInfo = {
  title: string;
  en: Logo;
  fi: Logo;
};
