/** @jsxImportSource theme-ui */
import EventRoll from '../../components/EventRoll';
import InfoRoll from '../../components/InfoRoll';
import LogoSection from '../../components/LogoSection';
import PerformanceRoll from '../../components/PerformanceRoll';
import QuoteRoll from '../../components/QuoteRoll';
import Section from '../../components/Section';
import type {
  Events,
  ImageData,
  ImageInfo,
  ImageOffsetsX,
  ImageSettings,
  InfoRollInfo,
  LangKey,
  Performances,
  Quotes,
} from '../../types';
import Tours from './Tours';

type Props = {
  content: string;
  credits: { credit: string }[];
  events: Events;
  image: ImageData;
  imageOffsetsX: ImageOffsetsX;
  imageOffsetY?: string;
  imageSettings?: ImageSettings;
  info: InfoRollInfo;
  langKey: LangKey;
  libraryTourImages: {
    credit: string;
    images?: ImageInfo[];
  };
  logos: string[];
  performances: Performances;
  quotes: Quotes;
  title: string;
};

const ToursPageTemplate = ({
  content,
  events,
  image,
  imageOffsetsX,
  imageOffsetY,
  imageSettings,
  info,
  libraryTourImages,
  logos,
  langKey,
  performances,
  quotes,
  title,
}: Props) => (
  <div>
    <Tours
      content={content}
      image={image}
      imageOffsetsX={imageOffsetsX}
      imageOffsetY={imageOffsetY}
      imageSettings={imageSettings}
      langKey={langKey}
      title={title}
    />
    {quotes.items.length > 0 && (
      <Section variant="black">
        <QuoteRoll
          heading={quotes.title}
          langKey={langKey}
          quotes={quotes.items}
        />
      </Section>
    )}
    <Section variant="blue" containerStyle={{ px: 0 }}>
      <EventRoll events={events} style={{ marginBottom: 6 }} />
      {!info.hide && (
        <InfoRoll credits={info.credits} items={info.items} langKey={langKey} />
      )}
    </Section>
    {!performances.hide && (
      <Section variant="purple">
        <PerformanceRoll
          credit={libraryTourImages.credit}
          heading={performances.title}
          performances={performances.descriptions}
        />
      </Section>
    )}
    <LogoSection langKey={langKey} logos={logos} />
  </div>
);

export default ToursPageTemplate;
