/** @jsxImportSource theme-ui */
import theme from '../../gatsby-plugin-theme-ui';

type Props = {
  amount: number | number[];
};

const Spacer = ({ amount }: Props) => {
  const flexBasis = Array.isArray(amount)
    ? amount.map((x) => theme.space?.[x])
    : theme.space?.[amount];
  return <div sx={{ flexBasis, flexGrow: 0 }} />;
};

export default Spacer;
