/** @jsxImportSource theme-ui */
import Cart from '../icons/Cart';
import Download from '../icons/Download';
import MapPin from '../icons/MapPin';
import type { IconProps } from '../icons/types';
import type { IconName } from '../types';

type Props = IconProps & {
  name: IconName;
};

const Icon = ({ name, ...iconProps }: Props) => {
  switch (name) {
    case 'cart':
      return <Cart {...iconProps} />;
    case 'download':
      return <Download {...iconProps} />;
    case 'map-pin':
      return <MapPin {...iconProps} />;
    default:
      return null;
  }
};

export default Icon;
