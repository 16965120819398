import { useState } from 'react';

type Value = boolean;

const getStorage = (): Storage | undefined =>
  typeof window !== 'undefined' ? window.localStorage : undefined;

const toBoolean = (value: string | null): boolean | null =>
  value && ['false', 'true'].includes(value) ? value === 'true' : null;

const useStorage = (key: string): [Value | null, (value: Value) => void] => {
  const storage = getStorage();
  const rawValue = storage?.getItem(key) ?? null;
  const parsedValue = toBoolean(rawValue);

  const [value, setValue] = useState<boolean | null>(parsedValue);
  const setFunction = (newValue: boolean) => {
    storage?.setItem(key, newValue.toString());
    setValue(newValue);
  };
  return [value, setFunction];
};

export default useStorage;
