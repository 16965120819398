import type { LangKey } from '../types';

export const defaultLangKey = 'fi';

export function getOtherLangLabel(langKey: LangKey): string {
  return langKey === 'fi' ? 'EN' : 'FI';
}

export function getLangPath(langKey: LangKey): string {
  return langKey === defaultLangKey ? '' : `/${langKey}`;
}
