/** @jsxImportSource theme-ui */
import { Global, Interpolation } from '@emotion/react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer';
import { withPrefix } from 'gatsby';
import { Theme } from 'theme-ui';

import CookieBanner from '../components/CookieBanner';
import useSiteMetadata from '../hooks/useSiteMetadata';
import useStorage from '../hooks/useStorage';
import type { LangKey } from '../types';
import { globalStyles } from '../utils/styles';
import Footer from './Footer';
import Header from './Header';
import Main from './Main';
import ScrollToTop from './ScrollToTop';
import SkipToMainContent from './SkipToMainContent';

type Props = {
  children: React.ReactNode;
  isPreview?: boolean;
  langKey: LangKey;
  metadata: {
    description: string;
    images?: string[];
    title: string;
  };
  path: string;
  pathOtherLang: string;
};

const getIconLink = (size: number) => (
  <link
    key={size}
    rel="icon"
    type="image/png"
    href={`${withPrefix('/')}img/favicon-${size}x${size}.png`}
    sizes={`${size}x${size}`}
  />
);

const TemplateWrapper = ({
  children,
  isPreview = false,
  langKey,
  metadata,
  path,
  pathOtherLang,
}: Props) => {
  const { siteUrl } = isPreview ? { siteUrl: '' } : useSiteMetadata();
  const [isCookieConsent, setIsCookieConsent] = useStorage(
    'cookie-banner-acknowledged',
  );
  const { description, images = [], title } = metadata;
  const { ref, inView, entry } = useInView();
  return (
    <HelmetProvider>
      <Helmet>
        <html lang={`${langKey}`} />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        {[144, 96, 48, 32].map(getIconLink)}
        <meta name="theme-color" content="#010066" />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={`${siteUrl}${path}`} />
        {images.map((image) => (
          <meta
            key={image}
            property="og:image"
            content={`${siteUrl}${image}`}
          />
        ))}
        <meta property="og:image" content={`${siteUrl}/img/og-image.png`} />

        <meta
          name="twitter:card"
          content={images.length > 0 ? 'summary_large_image' : 'summary'}
        />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:image"
          content={`${siteUrl}${
            images.length > 0 ? images[0] : '/img/og-image.png'
          }`}
        />
      </Helmet>

      <Global styles={globalStyles as Interpolation<Theme>} />

      <SkipToMainContent langKey={langKey} />

      <div
        id="top"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Header langKey={langKey} pathOtherLang={pathOtherLang} />
        <div ref={ref} />
        <Main>{children}</Main>
        <Footer langKey={langKey} />
        <ScrollToTop langKey={langKey} isVisible={!!entry && !inView} />
      </div>

      {isCookieConsent === null && (
        <CookieBanner langKey={langKey} onResponse={setIsCookieConsent} />
      )}
    </HelmetProvider>
  );
};

export default TemplateWrapper;
