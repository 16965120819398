/** @jsxImportSource theme-ui */
import { defaultHeight } from './defaults';
import type { IconProps } from './types';

const Cart = ({ height = defaultHeight, style }: IconProps) => (
  <svg
    fill="currentColor"
    height={height}
    width={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    sx={style}
  >
    <g fillRule="evenodd">
      <path d="m0 1.14285714c0-.63117714.46718087-1.14285714 1.04347826-1.14285714h2.79342261c.42667826 0 .81037565.28451429.96883826.71841143l.57242087 1.56730286h17.0806226c.7381565 0 1.2429913.81660571.9687652 1.56730285l-3.0766956 8.42446856c-.3169044.8677714-1.0843826 1.4368-1.9377392 1.4368h-9.84505039l-1.39670609 2.2857143h12.65473048c.576313 0 1.0434782.5116571 1.0434782 1.1428571s-.4671652 1.1428572-1.0434782 1.1428572h-14.6086957c-.38516869 0-.73901217-.2323429-.92045217-.6044572-.18144-.3721142-.16095652-.8234285.05326957-1.1740571l2.48745391-4.0707429-3.70722783-10.15074281h-2.08695652c-.57629739 0-1.04347826-.51168-1.04347826-1.14285715zm8.71729043 10.28571426h9.69582257l2.5043479-6.85714283h-14.70451829z" />
      <path
        d="m7.82608696 22c0 1.1045714-.81756522 2-1.82608696 2s-1.82608696-.8954286-1.82608696-2 .81756522-2 1.82608696-2 1.82608696.8954286 1.82608696 2zm11.21739134 2c1.0085217 0 1.8260869-.8954286 1.8260869-2s-.8175652-2-1.8260869-2c-1.0085218 0-1.826087.8954286-1.826087 2s.8175652 2 1.826087 2z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Cart;
