/** @jsxImportSource theme-ui */
import type { PersonInfos } from '../types';
import { itemSpacingSx } from '../utils/spacing';
import TextContent from './TextContent';
import TinyImage from './TinyImage';
import { getLinkId } from '../utils/links';

type Props = {
  imageShape: 'circle' | 'roundedRectangle';
  infos: PersonInfos;
};

const PersonRoll = ({ imageShape, infos }: Props) => (
  <>
    <h2>{infos.title}</h2>
    {infos.descriptions?.map((person) => (
      <div id={getLinkId(person.name)} key={person.name} sx={itemSpacingSx}>
        <h3 sx={{ fontSize: 4, marginBottom: 3 }}>{person.name}</h3>
        <div sx={{ maxWidth: `${900 - 2 * 24}px` }}>
          {person.image && (
            <TinyImage
              imageDimension={200}
              imageInfo={person.image}
              imageShape={imageShape}
              style={{
                float: 'right',
                marginLeft: 4,
                marginTop: 2,
              }}
            />
          )}
          <TextContent content={person.description} />
        </div>
      </div>
    ))}
  </>
);

export default PersonRoll;
