import { keyframes } from '@emotion/react';

export const easings = ['ease-in-out', 'ease-out'];
export const timings = ['100ms', '200ms', '500ms', '1s'];

const fadeInKeyFrames = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const fadeInAnimation = `${fadeInKeyFrames} ${timings[2]} ${easings[1]};`;
export const delayedFadeInAnimation = `${fadeInKeyFrames} ${timings[2]} 1s ${easings[1]} forwards;`;
