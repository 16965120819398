import { graphql } from 'gatsby';

// This is needed for CMS previews and should be the same value as 'maxWidth' in the fragment below,
// keep them in sync
export const backgroundImageWidth = 1900;

export const backgroundImage = graphql`
  fragment BackgroundImage on File {
    childImageSharp {
      left: gatsbyImageData(
        width: 1900
        height: 1650
        transformOptions: { cropFocus: EAST }
      )
      right: gatsbyImageData(
        width: 1900
        height: 1650
        transformOptions: { cropFocus: WEST }
      )
      rightSquare: gatsbyImageData(
        width: 1900
        height: 1900
        transformOptions: { cropFocus: WEST }
      )
      smallerHeight: gatsbyImageData(
        width: 1900
        height: 1350
        transformOptions: { cropFocus: CENTER }
      )
    }
  }
`;
