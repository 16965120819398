/** @jsxImportSource theme-ui */
import type { ThemeUIStyleObject } from 'theme-ui';

type Props = {
  children: React.ReactNode;
  style?: ThemeUIStyleObject;
};

const List = ({ children, style }: Props) => (
  <ul
    sx={{
      listStyle: 'none',
      margin: 0,
      ...style,
    }}
  >
    {children}
  </ul>
);

export default List;
