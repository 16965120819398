/** @jsxImportSource theme-ui */
import { ImageInfo } from '../../types';

import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';

type Props = {
  image: ImageInfo;
};

const ClubImage = ({ image }: Props) => (
  <figure sx={{ marginBottom: 5, maxWidth: '100%', position: 'relative' }}>
    <PreviewCompatibleImage imageInfo={image} style={{ boxShadow: 'xl' }} />
    <figcaption
      sx={{
        fontSize: 1,
        lineHeight: 'shortText',
        textAlign: image.descriptionPosition ?? 'right',
        textShadow: 'text',
        position: 'absolute',
        bottom: 0,
        [image.descriptionPosition ?? 'right']: '0.25rem',
      }}
    >
      {image.description}
    </figcaption>
  </figure>
);

export default ClubImage;
