import { graphql, useStaticQuery } from 'gatsby';

type Site = {
  siteMetadata: {
    buildDate: string;
    siteUrl: string;
  };
};

const useSiteMetadata = () => {
  const { site }: { site: Site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            buildDate
            siteUrl
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};

export default useSiteMetadata;
