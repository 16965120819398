/** @jsxImportSource theme-ui */
import type { ImageInfo, LangKey, OriginalImageData } from '../../types';
import ImageDownload from './ImageDownload';

type Props = {
  images: ImageInfo<OriginalImageData>[];
  langKey: LangKey;
};

const ImageDownloadRoll = ({ images, langKey }: Props) => (
  <div
    sx={{
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
      rowGap: 7,
      columnGap: 5,
    }}
  >
    {images.map((image) => (
      <ImageDownload imageInfo={image} key={image.alt} langKey={langKey} />
    ))}
  </div>
);

export default ImageDownloadRoll;
