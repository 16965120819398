/** @jsxImportSource theme-ui */
import Section from '../../components/Section';
import TextContent from '../../components/TextContent';

type Props = {
  content: string;
};

const MimeEvents = ({ content }: Props) => (
  <Section as="article" variant="purpleWithWave">
    <TextContent content={content} style={{ h1: { fontSize: '1.51572rem' } }} />
  </Section>
);

export default MimeEvents;
