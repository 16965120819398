/** @jsxImportSource theme-ui */
import Layout from '../templates/layout/Layout';

const NotFoundPage = () => (
  <Layout
    langKey="fi"
    metadata={{
      description: 'Sivua ei löytynyt. / The page doesn’t exist.',
      title: 'Sivua ei löytynyt / Page Not Found',
    }}
    path="/"
    pathOtherLang="/en"
  >
    <div sx={{ marginTop: 5, textAlign: 'center' }}>
      <h1 sx={{ marginBottom: 2 }}>Sivua ei löytynyt</h1>
      <div>
        <p>Etsimäsi sivu on saatettu siirtää muualle tai poistaa.</p>
      </div>

      <h1 sx={{ marginBottom: 2 }}>Page not found</h1>
      <div>
        <p>The page you were looking for may have been moved or deleted.</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
