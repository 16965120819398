import type { ThemeUIStyleObject } from 'theme-ui';
import Typography from 'typography';
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants';

import {
  bodyFontFamilies,
  headerFontFamilies,
} from '../../gatsby-plugin-theme-ui/font-stacks';

const typography = new Typography({
  baseFontSize: '20px',
  baseLineHeight: 1.5,
  headerFontFamily: headerFontFamilies,
  bodyFontFamily: bodyFontFamilies,
  blockMarginBottom: 0.75,
  overrideStyles: ({ adjustFontSizeTo }) => ({
    img: { marginBottom: 0 },
    [MOBILE_MEDIA_QUERY]: {
      h1: {
        ...adjustFontSizeTo('1.75rem'),
        lineHeight: 1.1,
      },
    },
  }),
});

export const { scale, rhythm, options } = typography;
export default typography;

export const smallestHeadingTextSx: ThemeUIStyleObject = {
  fontFamily: 'heading',
  fontSize: 1,
  fontWeight: 'lite',
  lineHeight: 'heading',
};
export const smallHeadingTextSx: ThemeUIStyleObject = {
  ...smallestHeadingTextSx,
  fontSize: 2,
};
