/** @jsxImportSource theme-ui */
import { graphql, PageProps } from 'gatsby';

import Layout from './layout/Layout';
import VideosPageTemplate from './pages/videos/VideosPageTemplate';
import type {
  Frontmatter,
  ImageData,
  ImageOffsetsX,
  PageContext,
  VideoInfo,
} from './types';

export type Props = PageProps<
  {
    markdownRemark: {
      frontmatter: Frontmatter & {
        image: ImageData;
        imageOffsetsX: ImageOffsetsX;
        imageOffsetY?: string;
        logos: string[];
        videos: VideoInfo[];
      };
      rawMarkdownBody: string;
    };
  },
  PageContext
>;

const VideosPage = ({ data, pageContext }: Props) => {
  const { frontmatter, rawMarkdownBody } = data.markdownRemark;

  return (
    <Layout
      langKey={pageContext.langKey}
      metadata={{
        description: frontmatter.meta.description,
        title: frontmatter.title,
      }}
      path={frontmatter.path}
      pathOtherLang={frontmatter.pathOtherLang}
    >
      <VideosPageTemplate
        {...frontmatter}
        content={rawMarkdownBody}
        langKey={pageContext.langKey}
      />
    </Layout>
  );
};

export default VideosPage;

export const pageQuery = graphql`
  query VideosPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      rawMarkdownBody
      fields {
        langKey
      }
      frontmatter {
        image {
          ...BackgroundImage
        }
        imageOffsetsX {
          _0_xs
          _1_sm
          _2_md
          _3_lg
          _4_xl
          _5_xxl
        }
        imageOffsetY
        logos
        path
        pathOtherLang
        videos {
          description
          duration
          id
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 200, layout: FIXED)
              }
            }
          }
          orientation
          title
        }
        title
        meta {
          description
        }
      }
    }
  }
`;
