/** @jsxImportSource theme-ui */
import React from 'react';
import zenscroll from 'zenscroll';

import scrollUp from '../img/scroll-up.svg';
import type { LangKey } from '../types';
import { easings, timings } from '../utils/animations';

type Props = {
  isVisible: boolean;
  langKey: LangKey;
};

class ScrollToTop extends React.Component<Props> {
  componentDidMount() {
    zenscroll.setup(400);
  }

  render() {
    const { isVisible, langKey } = this.props;
    const label = langKey === 'fi' ? 'Takaisin ylös' : 'Back to top';
    return (
      <a
        href="#top"
        sx={{
          position: 'fixed',
          padding: 2,
          backgroundColor: 'semiTransparent',
          borderRadius: 1,
          color: 'white',
          opacity: isVisible ? 0.5 : 0,
          bottom: 4,
          right: 4,
          transform: isVisible ? 'none' : 'translateY(64px)',
          transition: `opacity ${timings[2]} ${easings[0]}`,
          '&:hover': {
            opacity: 1,
          },
        }}
        tabIndex={isVisible ? 0 : -1}
        title={label}
      >
        <img
          alt={label}
          src={scrollUp}
          sx={{ height: '16px', margin: 0, width: '28px' }}
        />
      </a>
    );
  }
}

export default ScrollToTop;
