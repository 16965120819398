/** @jsxImportSource theme-ui */
import type {
  ImageData,
  ImageOffsetsX,
  ImageSettings,
  LangKey,
} from '../../types';
import { screenReadersOnlySx } from '../../utils/screen-readers';
import { containerSpacingSx } from '../../utils/spacing';
import ContentWithImageOnTheSide from '../../components/ContentWithImageOnTheSide';
import TextContent from '../../components/TextContent';

type Props = {
  content: string;
  image: ImageData;
  imageOffsetsX: ImageOffsetsX;
  imageOffsetY?: string;
  imageSettings?: ImageSettings;
  langKey: LangKey;
  title: string;
};

const Club = ({
  content,
  image,
  imageOffsetsX,
  imageOffsetY,
  imageSettings,
  langKey,
  title,
}: Props) => (
  <section sx={{ backgroundColor: ['white', null, null, 'blackPure'] }}>
    <ContentWithImageOnTheSide
      backgroundColor="black"
      imageBackgroundColor="whitePure"
      image={image}
      imageOffsetsX={imageOffsetsX}
      imageOffsetY={imageOffsetY}
      imageSettings={imageSettings}
      yPosition="top"
    >
      <div
        sx={{
          marginLeft: 'auto',
          maxWidth: 'textPlusPadding',
          ...containerSpacingSx,
        }}
      >
        <h1>{title}</h1>
        <h2 sx={screenReadersOnlySx}>
          {langKey === 'fi' ? 'Kuvaus' : 'Description'}
        </h2>
        <TextContent content={content} />
      </div>
    </ContentWithImageOnTheSide>
  </section>
);

export default Club;
