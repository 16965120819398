/** @jsxImportSource theme-ui */
import { Container } from 'theme-ui';

import TextContent from '../../components/TextContent';
import type { ImageInfo, LangKey, OriginalImageData } from '../../types';
import { containerSpacingSx } from '../../utils/spacing';
import ImageDownloadRoll from './ImageDownloadRoll';

type Props = {
  content: string;
  images: ImageInfo<OriginalImageData>[];
  langKey: LangKey;
  title: string;
};

const PressPageTemplate = ({ content, images, langKey, title }: Props) => (
  <Container sx={{ maxWidth: 'main', ...containerSpacingSx }}>
    <h1>{title}</h1>
    {content && <TextContent content={content} />}
    <ImageDownloadRoll images={images} langKey={langKey} />
  </Container>
);

export default PressPageTemplate;
