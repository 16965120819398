type CustomEventArgs = {
  action: string;
  category: string;
  label?: string;
  value?: number;
};

export const trackCustomEvent = ({
  action,
  category,
  label,
  value,
}: CustomEventArgs): void => {
  // @ts-ignore
  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};
