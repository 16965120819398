/** @jsxImportSource theme-ui */
import { Container } from 'theme-ui';

import TextContent from '../../components/TextContent';
import { containerSpacingSx } from '../../utils/spacing';

type Props = {
  content: string;
};

const CookiesPageTemplate = ({ content }: Props) => (
  <Container sx={{ maxWidth: 'text', ...containerSpacingSx }}>
    <TextContent content={content} />
  </Container>
);

export default CookiesPageTemplate;
