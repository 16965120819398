/** @jsxImportSource theme-ui */
import type { LangKey, VideoInfo } from '../../types';
import { itemSpacingSx } from '../../utils/spacing';
import List from '../../components/List';
import Section from '../../components/Section';
import TextContent from '../../components/TextContent';
import Video from '../../components/Video';

type Props = {
  bottomText?: string;
  items: VideoInfo[];
  langKey: LangKey;
  title?: string;
};

const getAlignment = (index: number) =>
  index % 2 === 1 ? ('right' as const) : undefined;

const ZigZagRoll = ({ bottomText, items, langKey, title }: Props) => (
  <Section as="section" variant="black">
    {title ? (
      <h2 sx={{ textAlign: 'center', marginBottom: 7 }}>{title}</h2>
    ) : null}
    <List style={{ textAlign: 'center' }}>
      {items.map((item, index) => (
        <li key={item.title} sx={{ ...itemSpacingSx }}>
          <Video
            alignment={getAlignment(index)}
            description={item.description}
            duration={item.duration}
            id={item.id}
            image={item.image}
            langKey={langKey}
            orientation={item.orientation}
            title={item.title}
          />
        </li>
      ))}
    </List>
    {bottomText && (
      <div sx={{ marginTop: 5, textAlign: 'center' }}>
        <TextContent
          content={bottomText}
          style={{ fontSize: 4, margin: 'auto' }}
        />
      </div>
    )}
  </Section>
);

export default ZigZagRoll;
