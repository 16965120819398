/** @jsxImportSource theme-ui */
import type { PromoCard } from '../../types';
import List from '../../components/List';
import Promo from './Promo';

type Props = {
  promos: { cards: PromoCard[] };
};

const PromoSection = ({ promos }: Props) => (
  <section>
    <List>
      {promos.cards.map((promo) => (
        <Promo
          key={promo.title}
          backgroundColor={promo.backgroundColor}
          description={promo.description}
          events={promo.events}
          image={promo.image}
          imageLayout={promo.imageLayout}
          imageOffsetsX={promo.imageOffsetsX}
          imageOffsetY={promo.imageOffsetY}
          imageSettings={promo.imageSettings}
          linkText={promo.readMoreLinkText}
          linkUrl={promo.linkUrl}
          title={promo.title}
        />
      ))}
    </List>
  </section>
);

export default PromoSection;
