/** @jsxImportSource theme-ui */
import FooterLink from './FooterLink';

type Props = {
  children: React.ReactNode;
  to: string;
};

const SocialMediaLink = ({ children, to }: Props) => (
  <FooterLink style={{ display: 'inline-block', height: '30px' }} to={to}>
    {children}
  </FooterLink>
);

export default SocialMediaLink;
