/** @jsxImportSource theme-ui */
import { StaticQuery, graphql } from 'gatsby';

import type { LangKey, LogoInfo } from '../types';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import LinkExternal from './LinkExternal';

type RollProps = {
  langKey: LangKey;
  logoInfos: LogoInfo[];
};

const LogoSection = ({ langKey, logoInfos }: RollProps) => (
  <section
    sx={{
      alignItems: 'center',
      backgroundColor: 'white',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      py: 4,
    }}
  >
    {logoInfos
      .map((logoInfo) => logoInfo[langKey])
      .map((logo) => (
        <LinkExternal
          href={logo.link}
          key={logo.image.alt}
          style={{
            marginTop: 2,
            marginBottom: 2,
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          <PreviewCompatibleImage imageInfo={logo.image} />
        </LinkExternal>
      ))}
  </section>
);

const getLogos = (logoInfos: LogoInfo[], logos: string[]): LogoInfo[] =>
  logos
    .map((logoTitle) =>
      logoInfos.find((logoInfo) => logoInfo.title === logoTitle),
    )
    .filter((logoInfo): logoInfo is LogoInfo => !!logoInfo);

type WrapperProps = {
  langKey: LangKey;
  logos: string[];
};

const LogoSectionWrapper = ({ langKey, logos }: WrapperProps) => (
  <StaticQuery
    query={graphql`
      query LogosCollectionQuery {
        logos: allMarkdownRemark(
          filter: { frontmatter: { collection: { eq: "logos" } } }
        ) {
          nodes {
            frontmatter {
              title
              en {
                image {
                  alt
                  image {
                    childImageSharp {
                      original {
                        width
                        height
                      }
                      originalSize: gatsbyImageData(
                        height: 180
                        placeholder: NONE
                      )
                    }
                  }
                }
                link
              }
              fi {
                image {
                  alt
                  image {
                    childImageSharp {
                      original {
                        width
                        height
                      }
                      originalSize: gatsbyImageData(
                        height: 180
                        placeholder: NONE
                      )
                    }
                  }
                }
                link
              }
            }
          }
        }
      }
    `}
    render={(data: { logos: { nodes: { frontmatter: LogoInfo }[] } }) => (
      <LogoSection
        langKey={langKey}
        logoInfos={getLogos(
          data.logos.nodes.map((node) => node.frontmatter),
          logos,
        )}
      />
    )}
  />
);

export default LogoSectionWrapper;
