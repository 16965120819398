/** @jsxImportSource theme-ui */
import type { LangKey } from '../types';
import Navbar from './Navbar';

type Props = {
  langKey: LangKey;
  pathOtherLang: string;
};

const Header = ({ langKey, pathOtherLang }: Props) => (
  <header
    sx={{
      backgroundColor: 'white',
      fontFamily: 'heading',
      fontSize: 2,
      color: 'black',
    }}
  >
    <div sx={{ backgroundColor: 'black', height: '8px' }} />
    <div
      sx={{
        maxWidth: 'header',
        marginX: 'auto',
        paddingX: [2, 3, 4],
        paddingY: [2, null, 3, 3],
      }}
    >
      <Navbar langKey={langKey} pathOtherLang={pathOtherLang} />
    </div>
  </header>
);

export default Header;
