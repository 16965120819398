/** @jsxImportSource theme-ui */
import { LangKey } from '../types';

type Props = {
  heading: string;
  langKey: LangKey;
  quotes: { text: string }[];
};

const QuoteRoll = ({ heading, langKey, quotes }: Props) => (
  <div sx={{ textAlign: 'center' }}>
    {heading && <h2>{heading}</h2>}
    <div
      sx={{
        display: 'flex',
        flexDirection: ['column', null, null, null, 'row'],
        alignItems: ['center', null, null, null, 'flex-start'],
        justifyContent: 'space-around',
        '> * + *': {
          marginLeft: [0, null, null, null, 6],
          marginTop: [5, null, null, null, 0],
        },
      }}
    >
      {quotes.map((quote, index) => (
        <blockquote
          key={index}
          sx={{
            fontStyle: 'italic',
            lineHeight: 'quote',
            maxWidth: 'quote',
            '::before': {
              content: `"${langKey === 'en' ? '“' : '”'}"`,
              fontSize: 8,
              lineHeight: 0,
              position: 'relative',
              top: 3,
              left: '-9px',
            },
            '::after': {
              content: `"”"`,
              fontSize: 8,
              lineHeight: 0,
              position: 'relative',
              top: 3,
              right: quote.text[quote.text.length - 1] === '.' ? 0 : '-3px',
            },
          }}
        >
          {quote.text}
        </blockquote>
      ))}
    </div>
  </div>
);

export default QuoteRoll;
