/** @jsxImportSource theme-ui */
import type { ImageInfo, LangKey, OriginalImageData } from '../../types';
import { hasImageData } from '../../types';
import Icon from '../../components/Icon';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import TextContent from '../../components/TextContent';
import { trackCustomEvent } from '../../utils/analytics';
import { getImageWidth } from '../../utils/images';
import { smallestHeadingTextSx } from '../../utils/typography';

type Props = {
  imageInfo: ImageInfo<OriginalImageData>;
  langKey: LangKey;
};

type ImageData = {
  height: number;
  name: string;
  size: number;
  src: string;
  width: number;
};

const getFluidImageInfo = (
  imageInfo: Props['imageInfo'],
): ImageData | undefined => {
  if (hasImageData(imageInfo)) {
    const { original, parent } = imageInfo.image.childImageSharp;
    return { name: parent.base, size: parent.size, ...original };
  }
  return undefined;
};

const formatDimensions = (imageData: ImageData, langKey: LangKey) =>
  `${imageData.width} x ${imageData.height} ${
    langKey === 'fi' ? 'pikseliä' : 'pixels'
  }`;

const formatSize = (imageData: ImageData, langKey: string) => {
  const roundedSize = (imageData.size / 1_000_000).toFixed(1);
  const formattedSize =
    langKey === 'en' ? roundedSize : roundedSize.replace('.', ',');
  return `${formattedSize} ${langKey === 'fi' ? 'Mt' : 'MB'}`;
};

const getImageMetadata = (imageData: ImageData, langKey: LangKey) =>
  `${formatDimensions(imageData, langKey)}, ${formatSize(imageData, langKey)}`;

const ImageDownload = ({ imageInfo, langKey }: Props) => {
  const imageData = getFluidImageInfo(imageInfo);
  const maxWidth = hasImageData(imageInfo)
    ? getImageWidth(imageInfo.image)
    : undefined;
  return (
    <figure sx={{ margin: 0, maxWidth }}>
      <PreviewCompatibleImage
        imageInfo={imageInfo}
        style={{ boxShadow: 'xl' }}
      />
      <figcaption
        sx={{
          fontSize: 1,
          lineHeight: 'shortText',
          marginLeft: '-2px',
          marginTop: 2,
          '> * + *': {
            marginTop: '6px',
          },
        }}
      >
        {imageInfo.description && <div>{imageInfo.description}</div>}
        {imageInfo.credit && (
          <div>
            {langKey === 'fi' ? 'Kuva: ' : 'Photo: '}
            <TextContent
              components={{ p: 'span' }}
              content={imageInfo.credit}
              style={{ display: 'inline' }}
            />
          </div>
        )}
        {imageData && (
          <div sx={{ color: 'grayLightest' }}>
            {getImageMetadata(imageData, langKey)}
          </div>
        )}
        <div
          sx={{
            ...smallestHeadingTextSx,
            fontSize: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon name="download" style={{ marginRight: 1 }} />
          <a
            download={imageData?.name}
            href={imageData?.src}
            onClick={() =>
              trackCustomEvent({
                action: 'download',
                category: 'press photo',
                label: imageData?.name,
              })
            }
          >
            {langKey === 'fi' ? 'Lataa' : 'Download'}
          </a>
        </div>
      </figcaption>
    </figure>
  );
};

export default ImageDownload;
