/** @jsxImportSource theme-ui */
import type { ThemeUIStyleObject } from 'theme-ui';

import { linkSx } from '../utils/links';

type Props = {
  children: React.ReactNode;
  href: string;
  style?: ThemeUIStyleObject;
};

const LinkExternal = ({ children, href, style }: Props) => (
  <a href={href} rel="noopener" sx={{ ...linkSx, ...style }} target="_blank">
    {children}
  </a>
);

export default LinkExternal;
