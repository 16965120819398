/** @jsxImportSource theme-ui */
import type { ImageData, ImageInfo, ImageOffsetsX, LangKey } from '../../types';
import { screenReadersOnlySx } from '../../utils/screen-readers';
import { containerSpacingSx } from '../../utils/spacing';
import ContentWithImageOnTheSide from '../../components/ContentWithImageOnTheSide';
import ImageRoll from '../../components/ImageRoll';
import TextContent from '../../components/TextContent';

type Props = {
  content: string;
  image: ImageData;
  imageOffsetsX: ImageOffsetsX;
  imageOffsetY?: string;
  langKey: LangKey;
  title: string;
  workshopImages: ImageInfo[];
};

const WorkshopsHeader = ({
  content,
  image,
  imageOffsetsX,
  imageOffsetY,
  langKey,
  title,
  workshopImages,
}: Props) => (
  <section>
    <ContentWithImageOnTheSide
      yPosition="bottom"
      image={image}
      imageLayout="right"
      imageOffsetsX={imageOffsetsX}
      imageOffsetY={imageOffsetY}
    >
      <div sx={{ maxWidth: 'container', ...containerSpacingSx }}>
        <h1>{title}</h1>
        <h2 sx={screenReadersOnlySx}>
          {langKey === 'fi' ? 'Kuvaus' : 'Description'}
        </h2>
        <TextContent content={content} />
      </div>
      <ImageRoll images={workshopImages} style={{ marginTop: 2 }} />
    </ContentWithImageOnTheSide>
  </section>
);

export default WorkshopsHeader;
