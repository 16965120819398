/** @jsxImportSource theme-ui */
import { defaultHeight } from './defaults';
import type { IconProps } from './types';

const Download = ({ height = defaultHeight, style }: IconProps) => (
  <svg
    fill="currentColor"
    height={height}
    width={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    sx={style}
  >
    <path d="m15.5907 10.0001-2.5857 2.5857v-9.58574c0-.03452-.0018-.06863-.0052-.10224-.0512-.50426-.4771-.89776-.9948-.89776-.5523 0-1 .44772-1 1v9.58574l-2.58583-2.5858c-.39052-.39049-1.02369-.39049-1.41421 0-.39053.3906-.39053 1.0237 0 1.4143l4.29294 4.2929c.1875.1875.4419.2929.7071.2929s.5195-.1054.7071-.2929l4.2929-4.2929c.0488-.0488.0915-.1014.1281-.1569.2563-.3881.2136-.9156-.1281-1.2573-.3906-.39055-1.0237-.39055-1.4143 0z" />
    <path d="m4.00488 14.0001c.55229 0 1 .4477 1 1v4h14.00002v-4c0-.5523.4477-1 1-1s1 .4477 1 1v4c0 1.1046-.8954 2-2 2h-14.00002c-1.10457 0-2-.8954-2-2v-4c0-.5523.44772-1 1-1z" />
  </svg>
);

export default Download;
