/** @jsxImportSource theme-ui */
import { defaultHeight } from './defaults';
import type { IconProps } from './types';

const Instagram = ({ height = defaultHeight, style, title }: IconProps) => (
  <svg
    stroke="currentColor"
    height={height}
    width={height}
    strokeWidth="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    sx={style}
  >
    {title && <title>{title}</title>}
    <rect
      fill="none"
      height="21"
      rx="3.82"
      strokeMiterlimit="10"
      width="21"
      x="1.5"
      y="1.5"
    />
    <circle cx="12" cy="12" fill="none" r="4.77" strokeMiterlimit="10" />
    <circle cx="18.2" cy="5.8" r="1.43" />
  </svg>
);

export default Instagram;
